import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchAuth, fetchSave} from "../store/reducers/ActionCreator";

import Autocomplete from 'react-google-autocomplete'
import Parser from 'html-react-parser'

import {ReactComponent as Logo} from '../assets/images/logo.svg'
import {ReactComponent as ArrowLong} from '../assets/images/arrow-long.svg'
import {ReactComponent as Flight} from '../assets/images/flight_takeoff.svg'
import {ReactComponent as Arrival} from '../assets/images/arrivals.svg'
import {ReactComponent as Mall} from '../assets/images/local_mall.svg'
import {ReactComponent as Shipping} from '../assets/images/local_shipping.svg'
import {ReactComponent as ArrowRight} from "../assets/images/arrow-right.svg";

import {ReactComponent as AppleIcon} from '../assets/images/apple.svg'
import {ReactComponent as GooglePlay} from '../assets/images/playmarket.svg'
import {ReactComponent as Youtube} from '../assets/images/youtube.svg'
import {ReactComponent as Instagram} from '../assets/images/instagram.svg'
import {ReactComponent as Telegram} from '../assets/images/telegram.svg'
import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'
import {ReactComponent as ArrowBack} from "../assets/images/back.svg";
import {ReactComponent as ExternalLink} from "../assets/images/external-link.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";

import {Swiper, SwiperSlide} from "swiper/react";
import {percent} from "../services/percent";
import {Navigation} from "swiper";
import Skeleton from "../components/skeleton/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import {languageSlice} from "../store/reducers/Language";



const Traveler: React.FC = () => {
    let language = useAppSelector(state => state.LanguageReducer.value);
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {save, isLoading, error} = useAppSelector(state => state.SaveReducer)
    const {voyage} = useAppSelector(state => state.StepReducer)
    const {auth} = useAppSelector((state) => state.AuthReducer)

    const destinationRef = React.useRef<HTMLInputElement>(null)
    const arrivalRef = React.useRef<HTMLInputElement>(null)

    const saveNavigationPrevRef = React.useRef<HTMLButtonElement>(null)
    const saveNavigationNextRef = React.useRef<HTMLButtonElement>(null)
    const elementToScroll = React.useRef<HTMLDivElement>(null)
    const popupWindowRef = React.useRef<HTMLDivElement>(null)
    const searchButtonRef = React.useRef<HTMLAnchorElement>(null)

    const [destinationAddress, setDestinationAddress] = React.useState('')
    const [arrivalAddress, setArrivalAddress] = React.useState('')

    const [activePopup, setActivePopup] = React.useState(false)
    const [popupLoadCard, setPopupLoadCard] = React.useState(1)
    const [isOpenMore, setIsOpenMore] = React.useState(false)
    const emailInput = React.useRef<HTMLInputElement>(null)
    const [email, setEmail] = React.useState<string | null>('')

    useEffect(() => {
        dispatch(fetchSave('https://app.weareway.com/en/get/secret/all?landing=traveler'))
        dispatch(fetchAuth())
        const handleClickOutside = (event: any) => {
            const path = event.path || (event.composedPath && event.composedPath())
            if (
                !path.includes(popupWindowRef.current) &&
                !event.target.closest('.save-card')
            ) {
                setActivePopup(false)
            }
        }
        document.body.addEventListener('click', handleClickOutside)

        return () => document.body.removeEventListener('click', handleClickOutside)
    }, [])

    return (
        <>
            <header className='header'>
                <div className='header__container container'>
                    <a className='header__logo' href='/'>
                        <Logo/>
                    </a>
                    <div>
                        {/*<div className="language-switcher-h">
                            <img src="/assets/images/translate.svg" alt="Translate"/>
                            <select value={language} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>

                                <option value='en'>En</option>
                                <option value='ru'>Ru</option>
                            </select>


                        </div>*/}
                        <a className='header__button button button--lg button--secondary button--fix-40'
                           href={auth ? `/${language}/connect`:`/${language}/user/login`}>{auth ? <FormattedMessage id='go_to_site' /> : <FormattedMessage id='log_in' />}</a>
                    </div>
                </div>
            </header>
            <section className='search'>
                <div className='search__container container'>
                    <p className='text search__first-row'>
                        <FormattedMessage id='find' />&nbsp;<span className='text text--xlg'><FormattedMessage id='your_way' /></span>
                    </p>
                    <p className='text search__second-row'>
                        <FormattedMessage id='to_earn' />&nbsp;<span className='text text--lg'><FormattedMessage id='while_traveling' /></span>
                    </p>
                    <p className='text text--4lg search__description'>
                        <FormattedMessage id='branding' />
                    </p>
                    <a
                        onClick={() =>
                            elementToScroll.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                        className='button button--text text--5lg'
                    >
                        <FormattedMessage id='learn_how' />
                        <ArrowLong />
                    </a>
                    <div className='search__form'>
                        <div className='search__input'>
                            <div className='search__input-content'>
                                <Flight />


                                <Autocomplete
                                    className='text text--sm'
                                    placeholder={intl.formatMessage({id: 'destination_point'})}
                                    apiKey={'AIzaSyDt6omtAIAbd8yWL4DKmTDE26IcBBGLh7M'}
                                    options={{
                                        types: ['locality', 'country'],
                                    }}
                                    onPlaceSelected={place => {
                                        searchButtonRef.current?.classList.remove('disabled')
                                        setDestinationAddress(place.formatted_address)

                                    }}
                                    ref={destinationRef}
                                     onChange={() => {
                                           if (destinationRef.current !== null) {

                                               if ((arrivalRef.current && arrivalRef.current.value.length < 1) && (destinationRef.current && destinationRef.current.value.length < 1)) {
                                                   setDestinationAddress(destinationRef.current.value)
                                                   searchButtonRef.current && searchButtonRef.current.classList.add('disabled')
                                               }
                                           }
                                     }}/>
                            </div>
                        </div>
                        <div className='search__input'>
                            <div className='search__input-content'>
                                <Arrival />

                                <Autocomplete
                                    ref={arrivalRef}
                                    className='text text--sm'
                                    placeholder={intl.formatMessage({id: 'arrival_point'})}
                                    apiKey={'AIzaSyDt6omtAIAbd8yWL4DKmTDE26IcBBGLh7M'}
                                    options={{
                                        types: ['locality', 'country'],
                                    }}
                                    onPlaceSelected={place => {
                                        searchButtonRef.current?.classList.remove('disabled')
                                        setArrivalAddress(place.formatted_address)

                                    }}

                                    onChange={() => {
                                           if (arrivalRef.current !== null) {
                                               if ((arrivalRef.current && arrivalRef.current.value.length < 1) && (destinationRef.current && destinationRef.current.value.length < 1)) {
                                                   setArrivalAddress(arrivalRef.current.value)
                                                   searchButtonRef.current && searchButtonRef.current.classList.add('disabled')
                                               }
                                           }
                                       }}

                                />
                            </div>
                        </div>{' '}
                        <a
                            href={`/${language}/user/landing?create=voyage_connect&from=${destinationAddress}&to=${arrivalAddress}`}
                            ref={searchButtonRef}
                            className='search__button button button--primary disabled'
                        >
                            <FormattedMessage id='search' />
                        </a>
                    </div>
                </div>
            </section>
            <section className='cards'>
                <div className='cards__container container'>
                    <Swiper scrollbar={{ draggable: true }} spaceBetween={24} slidesPerView={'auto'}>
                        <SwiperSlide>
                            <div className='cards__item'>
                                <Mall />
                                <span className='text text--sm'>
									<FormattedMessage id='card_one' />
								</span>{' '}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='cards__item'>
                                <Shipping />
                                <span className='text text--sm'>
									<FormattedMessage id='card_two' />
								</span>{' '}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='cards__item'>
                                <Arrival />
                                <span className='text text--sm'>
									<FormattedMessage id='card_three' />
								</span>{' '}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className='save'>
                <div className='save__top container'>
                    <div className='save__title'>
                        <div className='save__flex-line'>
                            <h2 className='text text--2lg'><FormattedMessage id='deliver_reward' /></h2>
                            <a href={`${language}/waw/save`} className='button button--text'>
                                <FormattedMessage id='see_more' />
                                <ArrowLong />
                            </a>
                        </div>
                        <p className='text text--sm'>
                            <FormattedMessage id='deliver_desc' />
                        </p>
                    </div>
                    <div className='save__control'>
                        <button
                            ref={saveNavigationPrevRef}
                            className='save__button save__prev-slide'
                        >
                            <ArrowRight />
                        </button>
                        <button
                            ref={saveNavigationNextRef}
                            className='save__button save__next-slide'
                        >
                            <ArrowRight />
                        </button>
                    </div>
                </div>
                <div className='save__container container'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={'auto'}
                        navigation={{
                            prevEl: saveNavigationPrevRef.current
                                ? saveNavigationPrevRef.current
                                : undefined,
                            nextEl: saveNavigationNextRef.current
                                ? saveNavigationNextRef.current
                                : undefined,
                        }}
                        onBeforeInit={swiper => {
                            // @ts-ignore
                            swiper.params.navigation.prevEl = saveNavigationPrevRef?.current
                            // @ts-ignore
                            swiper.params.navigation.nextEl = saveNavigationNextRef?.current
                            swiper.navigation.init()
                        }}
                    >

                        { !isLoading && save !== undefined ?
                            save.map((elem, key) => (
                                <SwiperSlide
                                    key={key}
                                    onClick={() => {
                                        setPopupLoadCard(key)
                                        setActivePopup(true)
                                    }}
                                >
                                    <div className='save-card save-card--traveler'>
                                        <div className='save-card__top'>
                                            <img
                                                className='save-card__image'
                                                src={`https://app.weareway.com${elem.field_description_images !== undefined ? elem.field_description_images.split(',')[0]: '/sites/default/files/product.svg'}`}
                                                alt='Product photo'
                                            />


                                        </div>
                                        <div className='save-card__middle'>
                                            <div className='save-card__price'>
                                                <p className='text text--4lg save-card__new-price'>
                                                    $ {elem.field_discount_price}
                                                </p>
                                                <p className='text text--sm save-card__old-price'>
                                                    ${elem.field_basic_price}
                                                </p>
                                            </div>
                                            <h5 className='save-card__product-name text text--sm'>
                                                {elem.field_description_images !== undefined ? Parser(elem.field_product_name) : 'Empty'}
                                            </h5>
                                            <p className='save-card__product-country text text--2sm'>
                                                {elem.field_country}
                                            </p>
                                        </div>
                                        <div className='save-card__bottom'>
                                            <p className='text text--4lg'>${Math.round(elem.field_discount_price * 0.10)}</p>
                                            <span className='text text--sm'><FormattedMessage id='earn_on_delivery' /></span>

                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                            : [1,2,3,4].map((elem,key) => (
                                <SwiperSlide key={key}>
                                    <Skeleton/>
                                </SwiperSlide>)) }

                    </Swiper>
                </div>
                <div className='save__bottom container'>
                    <a
                        href={`/${language}/waw/save`}
                        className='save__more button button--secondary button--lg'
                    >
                        <FormattedMessage id='see_more' />
                        <ArrowLong />
                    </a>
                </div>
            </section>
            <section ref={elementToScroll} className='steps'>
                <div className='steps__container container'>
                    <div className='steps__top'>
                        <p className='text text--sm'><FormattedMessage id='how_works_title' /></p>
                        <h2 className='text text--2lg'>
                            <FormattedMessage id='how_works_desc' />
                        </h2>
                    </div>
                    <div className='steps__content'>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>1</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_one_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_one_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>2</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_two_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_two_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>3</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_three_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_three_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>4</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_four_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_four_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                    </div>
                    <div className='steps__control'>
                        <a
                            className='button steps__button button--lg button button--secondary'
                            href={`/${language}/user/login`}
                        >
                            <FormattedMessage id='earning_now' />
                            <ArrowLong />
                        </a>
                    </div>
                </div>
            </section>
            <section className='subscribe subscribe--traveler'>
                <div className='subscribe__container container'>
                    <p className='text text--sm'><FormattedMessage id='sign_in_title' /></p>
                    <h5 className='text text--2lg'>
                        <FormattedMessage id='sign_in_desc' />
                    </h5>
                    <div className='subscribe__content'>
                        <div className='subscribe__form'>
                            <input
                                onBlur={() =>
                                    setEmail(() => emailInput.current && emailInput.current.value)
                                }
                                ref={emailInput}
                                className='subscribe__email text text--sm'
                                type='email'
                                placeholder={intl.formatMessage({id: 'sign_in_placeholder'})}
                            />
                            <a
                                href={`/${language}/user/login?email=` + email}
                                className='subscribe__button button button--lg button--primary'
                            >
                                <FormattedMessage id='sign_in_title' />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='faq'>
                <div className='faq__container container'>
                    <div className='faq__top'>
                        <p className='text text--sm'><FormattedMessage id='faq' /></p>
                        <h2 className='text text--2lg'>
                            <FormattedMessage id='faq_desc' />
                        </h2>
                    </div>
                    <div className='faq__content'>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_one_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_one_desc' />
                            </p>
                        </div>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_two_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_two_desc' />
                            </p>
                        </div>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_three_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_three_desc' />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='app app--traveler'>
                <div className='app__container container'>
                    <div className='app__content'>
                        <p className='text text--sm'><FormattedMessage id='app' /></p>
                        <h5 className='text text--2lg'><FormattedMessage id='app_desc' /></h5>
                        <div className='app__buttons'>
                            <a
                                href='https://apps.apple.com/app/weareway/id1644489646'
                                className='button button--xlg button--white'
                            >
                                <AppleIcon />
                                App Store
                            </a>
                            <a
                                href='https://play.google.com/store/apps/details?id=com.weareway.twa'
                                className='button button--xlg button--white'
                            >
                                <GooglePlay />
                                Google Play
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='articles'>
                <div className='articles__container container'>
                    <h2 className='text text--2lg'><FormattedMessage id='article' /></h2>
                    <Swiper scrollbar={{ draggable: true }} spaceBetween={30} slidesPerView={'auto'}>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-one.jpg' alt='Articles Image' />

                                <h5 className='text text--sm'><FormattedMessage id='article_one_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='article_one_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/how-return-taxes-border`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='learn_more' />
                                    <ArrowLong />
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-two.jpg' alt='Articles Image' />

                                <h5 className='text text--sm'><FormattedMessage id='article_two_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='article_two_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/what-crowdshipping-and-how-make-money-while-traveling`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='learn_more' />
                                    <ArrowLong />
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-three.jpg' alt='Articles Image' />

                                <h5 className='text text--sm'> <FormattedMessage id='article_three_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='article_three_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/5-reasons-order-abroad`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='learn_more' />
                                    <ArrowLong />
                                </a>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className='directions directions--traveler'>
                <div className='directions__top container'>
                    <p className='text text--sm'><FormattedMessage id='direction_title' /></p>
                    <div className='directions__header'>
                        <h2 className='text text--2lg'><FormattedMessage id='direction_desc' /></h2>
                        <a
                            href={`/${language}/user/login`}
                            className='button button--text text text--3lg'
                        >
                            <FormattedMessage id='start_shopping' />
                            <ArrowLong />
                        </a>
                    </div>
                </div>
                <div className='directions__content'>
                    <div className='directions__item directions__mexico'>
                        <h5 className='text text--roboto'><FormattedMessage id='mexico' /></h5>
                    </div>
                    <div className='directions__item directions__usa'>
                        <h5 className='text text--roboto'><FormattedMessage id='usa' /></h5>
                    </div>
                    <div className='directions__item directions__uk'>
                        <h5 className='text text--roboto'><FormattedMessage id='uk' /></h5>
                    </div>
                    <div className='directions__item directions__germany'>
                        <h5 className='text text--roboto'><FormattedMessage id='germany' /></h5>
                    </div>
                    <div className='directions__item directions__turkey'>
                        <h5 className='text text--roboto'><FormattedMessage id='turkey' /></h5>
                    </div>
                </div>
                <div className='directions__control container'>
                    <a
                        href={`/${language}/user/login`}
                        className='button button--secondary button--lg'
                    >
                        <FormattedMessage id='start_shopping' />
                        <ArrowLong />
                    </a>
                </div>
            </section>
            <footer className='footer'>
                <div className='footer__container container'>
                    <div>
                        <a className='footer__logo' href='/'>
                            <Logo/>
                        </a>
                        <div className="language-switcher-f">
                            <p className='text text--sm'><FormattedMessage id='language_placeholder' />:</p>
                            <select value={language} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>
                                <option value='en'>English</option>
                                <option value='ru'>Russian</option>
                            </select>
                        </div>
                    </div>
                    <div className='footer__content'>
                        <div className='footer__contacts'>
                            <h5 className='text text--2md'><FormattedMessage id='contacts' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>Email</p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:info@weareway.com'
                                >
                                    info@weareway.com
                                </a>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='phone_number' /></p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:+442037696167'
                                >
                                    +442037696167
                                </a>
                            </div>
                        </div>
                        <div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='middle_east_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='middle_east_address1' /><br/>
                                    <FormattedMessage id='middle_east_address2' /><br/>
                                    <FormattedMessage id='middle_east_address3' /><br/>
                                    <FormattedMessage id='middle_east_address4' /><br/>
                                    <FormattedMessage id='middle_east_address5' /><br/>
                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='middle_east_country' /></p>
                            </div>
                        </div>
                        <div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='european_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='european_address1' /><br/>
                                    <FormattedMessage id='european_address2' /><br/>
                                    <FormattedMessage id='european_address3' /><br/>
                                    {/*<FormattedMessage id='european_address4' /><br/>*/}

                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='european_country' /></p>
                            </div>
                        </div>
                        <div className='footer__legal'>
                            <h5 className='text text--2md'><FormattedMessage id='legal_documents' /></h5>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/term-use-weareway`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='term_use' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/privacy-policy`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='privacy_policy' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    className='footer__link text text--sm'
                                    href={`/${language}/terms/use-cookies`}
                                >
                                    <FormattedMessage id='use_cookies' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer__copyright container'>
                    <p className='text text--2sm'>
                        © {new Date().getFullYear()} WEAREWAY.CO LTD. <FormattedMessage id='right_reserved' />
                    </p>
                    <div className='footer__social'>
                        {' '}
                        <a
                            className='footer__social-button'
                            href='https://www.youtube.com/channel/UCBX_dsxetYMozv9jFjmU9gA'
                        >
                            <Youtube/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://instagram.com/we_are_way?igshid=YmMyMTA2M2Y='
                        >
                            <Instagram/>
                        </a>
                        <a className='footer__social-button' href='https://t.me/we_are_way'>
                            <Telegram/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://www.facebook.com/weareway2022'
                        >
                            <Facebook/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://twitter.com/weareway'
                        >
                            <Twitter/>
                        </a>
                    </div>
                </div>
            </footer>
            { !isLoading && save[popupLoadCard] !==undefined &&
                <div className={'popup' + ' ' + (activePopup && 'is-open')}>
                    <div className='popup__blur'></div>
                    <div ref={popupWindowRef} className='popup__action'>
                        <button
                            disabled={popupLoadCard === 0}
                            onClick={() => setPopupLoadCard((prev: any) => prev - 1)}
                            className='popup__arrow popup__arrow--prev'
                        >
                            <ArrowRight/>
                        </button>
                        <div className='popup__window'>
                            <div className='popup__container'>
                                <div className='popup__header'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__back'
                                    >
                                        <ArrowBack/>
                                    </button>
                                    <h5 className='text text--2md'>
                                        {Parser(save[popupLoadCard].field_product_name)}
                                    </h5>
                                </div>
                                <div className='popup__control'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__close'
                                    >
                                        <CloseIcon/>
                                    </button>
                                </div>
                                <div className='popup__content'>
                                    <div className='popup__card-info'>
                                        <img
                                            className='popup__product-image'
                                            src={`https://app.weareway.com${save[popupLoadCard].field_description_images !== undefined && save[popupLoadCard].field_description_images.split(',')[0]}`}
                                            alt='Product'
                                        />

                                        <div className='popup__info'>
                                            <h5 className='text text--4lg'>
                                                {Parser(save[popupLoadCard].field_product_name)}
                                            </h5>
                                            <div className='popup__new-price'>
                                                <p className='text text--5lg'>

                                                    $ {save[popupLoadCard].field_discount_price}
                                                </p>
                                                <p className='popup__country text text--sm'>
                                                    {save[popupLoadCard].field_country}
                                                </p>
                                            </div>
                                            <p className='popup__caption text text--xsm'>
                                                <FormattedMessage id='price_already' />
                                            </p>
                                            <div className='popup__old-price'>
											<span className='text text--sm'>
												$ {save[popupLoadCard].field_basic_price}
											</span>
                                                <p className='text text--sm'><FormattedMessage id='original_price' /></p>
                                            </div>
                                            <div className='popup__actions'>
                                                <div className='popup__discount'>
                                                    <h5 className='text text--3lg'>
                                                        {-1 *
                                                            percent(
                                                                save[popupLoadCard].field_discount_price,
                                                                save[popupLoadCard].field_basic_price
                                                            )}
                                                        %
                                                    </h5>
                                                    <p className='text text--2xsm'><FormattedMessage id='can_save' /></p>
                                                </div>
                                                <a href={`/${language}/user/landing?create=product&secret_id=${save[popupLoadCard].nid}`}
                                                   className='popup__order button button--primary button--lg'>
                                                    <FormattedMessage id='order' />
                                                </a>
                                            </div>
                                            <a
                                                className='popup__shop button button--text'
                                                href={save[popupLoadCard].field_shop_url}
                                            >
                                                <ExternalLink/>
                                                <FormattedMessage id='where_buy' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__description'>
                                        <h5 className='text text--2sm'><FormattedMessage id='product_description' /></h5>
                                        {save[popupLoadCard].field_description_text ? (<>
                                            <p
                                                className={
                                                    'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                                }
                                            >
                                                {Parser(save[popupLoadCard].field_description_text)}
                                            </p>
                                            <a
                                                onClick={() => {
                                                    setIsOpenMore(prev => !prev)
                                                }}
                                                className='button text text--2sm'
                                            >
                                                <FormattedMessage id='more' />
                                                <ArrowRight/>
                                            </a></>) : <p
                                            className={
                                                'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                            }
                                        >
                                            No description
                                        </p>
                                        }
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__details'>
                                        <h5 className='text text--sm'><FormattedMessage id='details' /></h5>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_name' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_name)}
                                            </p>
                                        </div>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_address' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_address_term)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={popupLoadCard === save.length - 1}
                            onClick={() => setPopupLoadCard((prev: any) => prev + 1)}
                            className='popup__arrow popup__arrow--next'
                        >
                            <ArrowRight/>
                        </button>
                    </div>
                </div>

            }
        </>
    )
}

export default Traveler

