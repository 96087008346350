import {LOCALES} from "./locales";

export const messages = {
    [LOCALES.ENGLISH] : {
        language_placeholder: 'Language',
        go_to_site: 'Go to site',
        log_in: `Log In`,
        shop_globally: `WeAreWay - a data portal for finding the fastest`,
        earn_travelling: `and cheapest delivery in the world`,
        branding: `Our AI ensures delivery through crowdshipping by connecting individuals in need of delivery
with travellers who can carry parcels or documents along their journey, bypassing third
parties.`,
        learn_how_works: `Learn more...`,
        i_want_order: `I want to order delivery`,
        order_from: `Order from`,
        deliver_to: `Deliver to`,
        search: `SEARCH`,
        most_travel_from: `Most travel from`,
        most_travel_to: `Most travel to`,
        its_bargain: `It's a bargain!`,
        i_want_travel: `I want to deliver and earn`,
        destination_point: `Enter destination point`,
        arrival_point: `Enter arrival point`,
        most_order_from: `Most order from`,
        most_order_to: `Most order to`,
        card_one_text: `Shop worldwide and AI will find the cheapest delivery option`,
        card_two_text: `Get your parcel faster than standard deliveries. Expect delivery within 24-48 hours from
Duty-Free`,
        card_three_text: `AI will suggest a traveller to deliver your goods and earn money`,
        best_seller_title: `Best seller and most rewarded`,
        see_more: `See more`,
        best_seller_desc: `Find out how much you can earn on the most popular items delivery`,
        reward_include: `The price already includes taxes and`,
        reward_delivery: `Reward for delivery`,
        ready_start: `Ready to start?`,
        ready_title: `Find an order to deliver or create an order`,
        ready_order_button: `Create order`,
        ready_voyage_button: `Create voyage`,
        step_one_order_title: `Set the task`,
        step_one_order_desc: `Specify what needs to be delivered its point of origin`,
        step_two_order_title: `Selecting travellers for delivery`,
        step_two_order_desc: `AI will verify journeys in the required direction, publish
messages in various traveller chats, analyse the offers and choose the 5 most convenient
and safe options based on many criteria`,
        step_three_order_title: `Choose a traveller`,
        step_three_order_desc: `Review the trip details and select the one that suits you best`,
        step_four_order_title: `Contact the traveller and agree on delivery`,
        step_four_order_desc: `AI will verify the credibility of the traveller and
provide their contact details for delivery. It's all fast, reliable and direct - no third parties
involved. WeAreWay takes minimal fees for the search`,
        step_one_travel_title: `Specify the route`,
        step_one_travel_desc: `Include details about your travel dates, starting location and
destination`,
        step_two_travel_title: `Identifying items for delivery`,
        step_two_travel_desc: `AI will conduct detailed analysis and propose delivery
options for goods and documents along your route, providing estimated pricing`,
        step_three_travel_title: `Suggest a price`,
        step_three_travel_desc: `Based on AI's guidance, propose your rate for delivery. AI will forward it
to the customer`,
        step_four_travel_title: `Agree to the delivery terms and finalize the arrangement with the customer`,
        step_four_travel_desc: `AI will verify
the credibility of the customer and provide their contact details for delivery. It's all fast,
reliable and direct - no third parties involved. WeAreWay takes minimal fees for the search`,
        start_earning: `Start earning now`,
        start_saving: `Start saving now`,
        register_user: `registered users`,
        voyages_count: `voyages`,
        orders_count: `orders`,
        app_title: `WeAreWay app`,
        app_desc: `The app is even more convenient`,
        faq: `FAQ`,
        faq_one_title: `Technology`,
        faq_one_desc: `AI is employed at every stage. The deep neural networks in WeAreWay perform complex
daily analysis and scanning of social networks to identify relevant traveller groups. The
advanced AI also analyses social media user profiles to assess their reliability and delivery
capability, as well as checks their geolocation and expected travel routes.`,
        faq_two_title: `Guarantee of Safety`,
        faq_two_desc: `AI verifies the credibility of both the customer and the traveller across all available social
networks and databases before the deal.{br}
AI manages WeAreWay's internal rating system to carefully select trustworthy and reliable
individuals.{br}
Customer support monitors the quality of provided information services 24/7 and is ready to
assist with any challenging tasks.`,
        faq_three_title: `Features`,
        faq_three_desc: `1. Neural Networks: Advanced AI algorithms designed for data search and analysis on
social networks, providing continuous updates on traveller groups.{br}
2. Flexibility: Ability to offer goods and documents for delivery anytime and anywhere.{br}
3. Cost Efficiency: Significant reduction in costs compared to standard logistics.{br}
4. Speed: Streamlining processes typically involved in traditional courier services.`,
        faq_four_title: `Opportunities and Benefits`,
        faq_four_desc: `WeAreWay provides an efficient, cost-effective alternative to traditional delivery methods.
Leveraging AI and neural networks simplifies the delivery process, enhancing convenience
for all parties involved - from senders to recipients.`,
        faq_five_title: `Ultimate Goal`,
        faq_five_desc: `WeAreWay aims to revolutionize delivery methods by introducing a completely new,
integrated and convenient way to ship goods and documents globally. Join WeAreWay and
together, we will overcome the typical barriers of international delivery.`,
        faq_six_title: `Implementation`,
        faq_six_desc: `June 2024: Launching the WeAreWay technology prototype in a WhatsApp bot interface.
Fully establishing the development and product support teams. {br} June 2025: Formulating a product development strategy, entering markets, scaling globally
through local partnerships and pickup locations.`,
        articles_title: `Helpful hints before a voyage`,
        articles_one_title: `How to get a tax refund at the border?`,
        articles_one_desc: `Shopping abroad is enjoyable, but sometimes too costly. Especially if you have not yet had time to start using the services of WeAreWay...`,
        articles_two_title: `What is crowdshipping?`,
        articles_two_desc: `It's expensive to travel. But it's not for everyone! And everyone can become a part of of the for a select few society...`,
        articles_three_title: `Basic restrictions at customs `,
        articles_three_desc: `Our world is so vast and each state has its own customs regulations. However, there are general rules that are important to follow if you are going to cross any border...`,
        articles_more: `Learn more`,
        contacts: `Contacts`,
        middle_east_office: `Middle East Office`,
        european_office: `European Office`,
        legal_documents: `Legal documents`,
        phone_number: `Phone`,
        middle_east_address1: `Amot`,
        middle_east_address2: `Atrium Tower`,
        middle_east_address3: `2 Jabotinsky Street`,
        middle_east_address4: `Ramat Gan`,
        middle_east_address5: `5250501`,
        middle_east_country: `Israel`,

        european_address1: `20 Hammersmith Broadway`,
        european_address2: `London`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `England`,
        term_use: `Terms of Use`,
        privacy_policy: `Privacy Policy`,
        use_cookies: `Use of Cookies`,
        right_reserved: `All rights reserved.`,
        price_already: `The price already includes platform expences`,
        product_description: `Product description`,
        original_price: `Original price`,
        can_save: `You can save`,
        order: 'Order',
        where_buy: `Where to buy`,
        details: `Details`,
        shop_name: `Shop name`,
        shop_address: `Shop address`,
        more: `More`,
    },
    [LOCALES.RUSSIAN]: {
        language_placeholder: 'Язык',
        go_to_site: 'Перейти на сайт',
        log_in: `Войти`,
        shop_globally: `WeAreWay - портал данных для поиска самой быстрой и`,
        earn_travelling: `дешевой доставки в мире`,
        branding: `Наш искусственный интеллект обеспечивает доставку с помощью краудшиппинга, соединяя людей, нуждающихся в доставке
с путешественниками, которые могут перевезти посылки или документы по пути следования, минуя третьих лиц.`,
        learn_how_works: `Узнать больше...`,
        i_want_order: `Я хочу заказать доставку`,
        order_from: `Откуда заказать`,
        deliver_to: `Куда доставить`,
        search: `НАЙТИ`,
        most_travel_from: `Большинство путешествуют из`,
        most_travel_to: `Большинство путешествуют в`,
        its_bargain: `Это выгодно!`,
        i_want_travel: `Я хочу доставлять и зарабатывать`,
        destination_point: `Введите страну и город отправления`,
        arrival_point: `Введите страну и город прибытия`,
        most_order_from: `Большинство совершают заказы из`,
        most_order_to: `Большинство совершают заказы в`,
        card_one_text: `Делайте покупки по всему миру, и ИИ найдет самый дешевый вариант доставки.`,
        card_two_text: `Получите свою посылку быстрее, чем при стандартной доставке. Ожидайте доставку в течение 24-48 часов из
Duty-Free`,
        card_three_text: `ИИ предложит путешественника для доставки ваших товаров и заработает деньги`,
        best_seller_title: `Самые популярные товары`,
        see_more: `Перейти в каталог`,
        best_seller_desc: `Узнайте, сколько можно заработать на доставке популярных товаров`,
        reward_include: `Цена уже включает налоги и`,
        reward_delivery: `Вознаграждение`,
        ready_start: `Готовы начать?`,
        ready_title: `Создайте заказ или подберите тот, который сможете доставить заказчику`,
        ready_order_button: `Создать заказ`,
        ready_voyage_button: `Создать путешествие`,
        step_one_order_title: `Поставьте задачу`,
        step_one_order_desc: `Укажите, что необходимо доставить в пункт отправления`,
        step_two_order_title: `Выбор путешественников для доставки`,
        step_two_order_desc: `ИИ будет проверять поездки в нужном направлении, публиковать
сообщения в различных чатах для путешественников, проанализирует предложения и выберет 5 самых удобных
и безопасных вариантов на основе множества критериев`,
        step_three_order_title: `Выберите путешественника`,
        step_three_order_desc: `Ознакомьтесь с деталями поездки и выберите ту, которая подходит вам больше всего`,
        step_four_order_title: `Свяжитесь с путешественником и договоритесь о доставке`,
        step_four_order_desc: `ИИ проверит благонадежность путешественника и
предоставит свои контактные данные для доставки. Все быстро, надежно и напрямую - никаких третьих сторон.
вовлеченных сторон. WeAreWay берет минимальную плату за поиск`,
        step_one_travel_title: `Укажите маршрут`,
        step_one_travel_desc: `Включите подробную информацию о датах поездки, месте отправления и
пункт назначения`,
        step_two_travel_title: `Определение предметов для доставки`,
        step_two_travel_desc: `ИИ проведет детальный анализ и предложит варианты доставки
товаров и документов по вашему маршруту, предоставляя примерные цены`,
        step_three_travel_title: `Предложите цену`,
        step_three_travel_desc: `Основываясь на рекомендациях ИИ, предложите свою цену за доставку. ИИ направит его
клиенту`,
        step_four_travel_title: `Согласование условий поставки и заключение договора с клиентом`,
        step_four_travel_desc: `ИИ проверит
доверие к клиенту и предоставит его контактные данные для доставки. Все это быстро,
надежно и напрямую - без участия третьих сторон. WeAreWay берет минимальную плату за поиск`,
        start_earning: `Начните зарабатывать уже сейчас`,
        start_saving: `Начните экономить уже сейчас`,
        register_user: `пользователей`,
        voyages_count: `совершенных путешествий`,
        orders_count: `совершенных заказов`,
        app_title: `Скачайте приложение WeAreWay`,
        app_desc: `В приложении ещё удобнее`,
        faq: `Часто задаваемые вопросы`,
        faq_one_title: `Технология`,
        faq_one_desc: `ИИ используется на каждом этапе. Глубокие нейронные сети в WeAreWay выполняют сложный
ежедневный анализ и сканирование социальных сетей для выявления релевантных групп путешественников. На сайте
продвинутый ИИ также анализирует профили пользователей социальных сетей, чтобы оценить их надежность и возможность доставки
надежности и возможности доставки, а также проверяет их геолокацию и предполагаемые маршруты поездок.`,
        faq_two_title: `Гарантия безопасности`,
        faq_two_desc: `ИИ проверяет достоверность информации о клиенте и путешественнике во всех доступных социальных сетях и базах данных до заключения сделки.
сетях и базах данных до заключения сделки.{br}
ИИ управляет внутренней системой рейтингов WeAreWay для тщательного отбора надежных и заслуживающих доверия людей.
лиц.{br}
Служба поддержки клиентов круглосуточно следит за качеством предоставляемых информационных услуг и готова
Поддержка клиентов следит за качеством предоставляемых информационных услуг 24 часа в сутки 7 дней в неделю и готова помочь в решении любых сложных задач.`,
        faq_three_title: `Особенности`,
        faq_three_desc: `1. Нейронные сети: Передовые алгоритмы искусственного интеллекта, предназначенные для поиска и анализа данных в
социальных сетях, обеспечивая непрерывное обновление данных о группах путешественников.{br}
2. Гибкость: Возможность предлагать товары и документы для доставки в любое время и в любом месте.{br}
3. Экономическая эффективность: Значительное снижение затрат по сравнению со стандартной логистикой.{br}
4. Скорость: оптимизация процессов, обычно связанных с традиционными курьерскими услугами.`,
        faq_four_title: `Возможности и преимущества`,
        faq_four_desc: `WeAreWay - это эффективная и экономичная альтернатива традиционным способам доставки.
Использование искусственного интеллекта и нейронных сетей упрощает процесс доставки, повышая удобство
для всех участников процесса - от отправителей до получателей.`,
        faq_five_title: `Конечная цель`,
        faq_five_desc: `WeAreWay стремится совершить революцию в способах доставки, представляя совершенно новый,
интегрированный и удобный способ доставки товаров и документов по всему миру. Присоединяйтесь к WeAreWay и
вместе мы преодолеем типичные барьеры международной доставки.`,
        faq_six_title: `Реализация`,
        faq_six_desc: `Июнь 2024 года: Запуск прототипа технологии WeAreWay в интерфейсе бота WhatsApp.
Полное создание команды разработчиков и службы поддержки продукта. {br} Июнь 2025 года: Разработка стратегии развития продукта, выход на рынки, глобальное масштабирование
через местные партнерства и пункты самовывоза.`,
        articles_title: `Полезные советы перед путешествием`,
        articles_one_title: `Как получить возврат налога на границе?`,
        articles_one_desc: `Покупки за границей дело приятное, но иногда чересчур затратное. Особенно если вы еще не успели начать пользоваться услугами WeAreWay...`,
        articles_two_title: `Что такое краудшиппинг?`,
        articles_two_desc: `Путешествовать дорого. Но не для всех! И каждый может стать частью сообщества "не для всех"....`,
        articles_three_title: `Основные ограничения на таможнях`,
        articles_three_desc: `Наш мир настолько большой и каждое государство имеет свои таможенные правила. Тем не менее, есть общие правила, которые важно соблюдать, если вы собираетесь пересекать какую-либо границу...`,
        articles_more: `Узнать больше`,
        contacts: `Контактная информация`,
        middle_east_office: `Офис Ближнего Востока`,
        european_office: `Офис Европы`,
        legal_documents: `Нормативные документы`,
        phone_number: `Телефон`,
        middle_east_address1: `Амот`,
        middle_east_address2: `Атриум Тауэр`,
        middle_east_address3: `Улица Жаботинского, 2`,
        middle_east_address4: `Рамат-Ган`,
        middle_east_address5: `5250501`,
        middle_east_country: `Израиль`,
        european_address1: `20 Hammersmith Broadway`,
        european_address2: `Лондон`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `Англия`,
        term_use: `Условия использования`,
        privacy_policy: `Политика конфиденциальности`,
        use_cookies: `Использование куков`,
        right_reserved: `Все права защищены.`,
        price_already: `Услуги сервиса включены в стоимость`,
        product_description: `Описание товара`,
        original_price: `Изначальная цена`,
        can_save: `Экономия`,
        order: 'Заказать',
        where_buy: `Где купить`,
        details: `Подробности`,
        shop_name: `Название магазина`,
        shop_address: `Адрес магазина`,
        more: `Больше`,
    }
}