import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchAuth, fetchSave, fetchTopSave} from "../store/reducers/ActionCreator";

import {FormattedMessage, useIntl} from 'react-intl'


import {ReactComponent as Logo} from '../assets/images/logo.svg'
import {ReactComponent as ArrowLong} from '../assets/images/arrow-long.svg'
import {ReactComponent as Arrival} from '../assets/images/arrivals.svg'
import {ReactComponent as Mall} from '../assets/images/local_mall.svg'
import {ReactComponent as Shipping} from '../assets/images/local_shipping.svg'
import {ReactComponent as ArrowRight} from "../assets/images/arrow-right.svg";
import {ReactComponent as Youtube} from '../assets/images/youtube.svg'
import {ReactComponent as Instagram} from '../assets/images/instagram.svg'
import {ReactComponent as Telegram} from '../assets/images/telegram.svg'
import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'
import {ReactComponent as ArrowBack} from "../assets/images/back.svg";
import {ReactComponent as ExternalLink} from "../assets/images/external-link.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";



import {Swiper, SwiperSlide} from "swiper/react";
import {percent} from "../services/percent";

import Parser from "html-react-parser";

import {languageSlice} from "../store/reducers/Language";
import FaqItem from "../components/frequency/FaqItem";



const Root: React.FC = () => {
    let language = useAppSelector(state => state.LanguageReducer.value);
    const dispatch = useAppDispatch()
    const {save, isLoading} = useAppSelector(state => state.SaveReducer)
    const elementToScroll = React.useRef<HTMLDivElement>(null)
    const popupWindowRef = React.useRef<HTMLDivElement>(null)
    const [activeStep, setActiveStep] = React.useState(0)
    const [activePopup, setActivePopup] = React.useState(false)
    const [popupLoadCard, setPopupLoadCard] = React.useState(1)
    const [isOpenMore, setIsOpenMore] = React.useState(false)




    useEffect(() => {
        dispatch(fetchSave('https://app.weareway.com/en/get/secret/all?landing=base'))
        dispatch(fetchTopSave('https://app.weareway.com/en/get/secret/all?landing=base'))
        dispatch(fetchAuth())

        const handleClickOutside = (event: any) => {
            const path = event.path || (event.composedPath && event.composedPath())
            if (
                !path.includes(popupWindowRef.current) &&
                !event.target.closest('.save-card')
            ) {
                setActivePopup(false)
            }
        }
        document.body.addEventListener('click', handleClickOutside)

        return () => document.body.removeEventListener('click', handleClickOutside)


    }, [])

    const intl = useIntl()

    return (
        <>
            <header className='header'>
                <div className='header__container container'>
                    <a className='header__logo' href='/'>
                        <Logo/>
                    </a>
                    <div>
                        {/*<div className="language-switcher-h">
                            <img src="/assets/images/translate.svg" alt="Translate"/>
                            <select value={useAppSelector(state => state.LanguageReducer.value)} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>

                                <option value='en'>En</option>
                                <option value='ru'>Ru</option>
                            </select>


                        </div>*/}
                    </div>
                </div>
            </header>
            <section className='search search--main'>
                <div className='search__container container'>
                    <p className='text text--xlg'><FormattedMessage id='shop_globally' /></p>
                    <p className='text text--xlg'><FormattedMessage id='earn_travelling' /></p>
                    <p className='text text--5lg'>
                        <FormattedMessage id='branding' />
                    </p>
                    <button
                        onClick={() =>
                            elementToScroll.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                        className='button button--text text--5lg intro__button'
                    >
                        <FormattedMessage id='learn_how_works' />
                        <ArrowLong/>
                    </button>
                    <section className='cards'>
                        <div className='cards__container container'>
                            <Swiper wrapperClass={'cards__wrapper'} spaceBetween={24} slidesPerView={'auto'}>
                                <SwiperSlide className={'cards__slider'}>
                                    <div className='cards__item'>
                                        <Mall/>
                                        <span className='text text--sm'>
									<FormattedMessage id='card_one_text' />
								</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={'cards__slider'}>
                                    <div className='cards__item'>
                                        <Shipping/>
                                        <span className='text text--sm'>
									<FormattedMessage id='card_two_text' />
								</span>{' '}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={'cards__slider'}>
                                    <div className='cards__item'>
                                        <Arrival/>
                                        <span className='text text--sm'>
									<FormattedMessage id='card_three_text' />
								</span>{' '}
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </section>
                </div>
            </section>
            <section ref={elementToScroll} className='steps steps--main'>
                <div className='steps__container container'>
                    <div className='steps__top'>
                        <div className='collection__tabs'>
                            <button
                                onClick={() => setActiveStep(0)}
                                className={
                                    'collection__tabs-item text text--4lg' +
                                    ' ' +
                                    (activeStep === 0 && 'active')
                                }
                            >
                                <FormattedMessage id='i_want_order' />
                            </button>
                            {' '}
                            <button
                                onClick={() => setActiveStep(1)}
                                className={
                                    'collection__tabs-item text text--4lg' +
                                    ' ' +
                                    (activeStep === 1 && 'active')
                                }
                            >
                                <FormattedMessage id='i_want_travel' />
                            </button>
                            {' '}
                        </div>
                    </div>
                    <div className='steps__content'>
                        {activeStep == 0 &&
                            (<>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>1</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_one_order_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_one_order_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>2</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_two_order_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_two_order_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>3</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_three_order_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_three_order_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>4</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_four_order_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_four_order_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                            </>)
                        }
                        {activeStep == 1 &&
                            <>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>1</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_one_travel_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_one_travel_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>2</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_two_travel_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_two_travel_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>3</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_three_travel_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_three_travel_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                                <div className='steps__item'>
                                    <div className='steps__item-content'>
                                        <div className='steps__count'>4</div>
                                        <h5 className='steps__title text text--md'><FormattedMessage id='step_four_travel_title' /></h5>
                                        <p className='steps__text text text--2sm'>
                                            <FormattedMessage id='step_four_travel_desc' />
                                        </p>
                                    </div>
                                    <div className='steps__arrow'></div>
                                </div>
                            </>}
                    </div>

                </div>
            </section>
            {/*<section className='video'>
                <div className='video-responsive'>
                    <iframe
                        width='853'
                        height='480'
                        src={`https://www.youtube.com/embed/0zuL_MqVaIA`}
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        title='Embedded youtube'
                    />
                </div>
            </section>
            <section className='statistics'>
                <div className='statistics__container container'>
                    <div className='statistics__content'>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/user.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>320</h5>
                                <p className='text text--md'><FormattedMessage id='register_user' /></p>
                            </div>
                        </div>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/voyage.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>560</h5>
                                <p className='text text--md'><FormattedMessage id='voyages_count' /></p>
                            </div>
                        </div>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/order.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>890</h5>
                                <p className='text text--md'><FormattedMessage id='orders_count' /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/} 
            <section className='faq-alt'>
                <div className='faq-alt__container container'>
                    <h2 className='text text--2lg'><FormattedMessage id='faq' /></h2>
                    <div className='faq-alt__content'>
                        <FaqItem
                            title={<FormattedMessage id='faq_one_title' />}
                            description={<FormattedMessage id='faq_one_desc' values={{ br: <br /> }} />}
                        />
                        <FaqItem
                            title={<FormattedMessage id='faq_two_title' />}
                            description={<FormattedMessage id='faq_two_desc' values={{ br: <br /> }} />}
                        />
                        <FaqItem
                            title={<FormattedMessage id='faq_three_title' />}
                            description={<FormattedMessage id='faq_three_desc' values={{ br: <br />}} />}
                        />
                        <FaqItem
                            title={<FormattedMessage id='faq_four_title' />}
                            description={<FormattedMessage id='faq_four_desc' values={{ br: <br /> }} />}
                        />
                        <FaqItem
                            title={<FormattedMessage id='faq_five_title' />}
                            description={<FormattedMessage id='faq_five_desc' values={{ br: <br /> }} />}
                        />
                        <FaqItem
                            title={<FormattedMessage id='faq_six_title'  />}
                            description={<FormattedMessage id='faq_six_desc' values={{ br: <br /> }} />}
                        />
                    </div>
                </div>
            </section>
            <section className='articles'>
                <div className='articles__container container'>
                    <h2 className='text text--2lg'><FormattedMessage id='articles_title' /></h2>
                    <Swiper scrollbar={{ draggable: true }} spaceBetween={30} slidesPerView={'auto'}>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-one.jpg' alt='Articles Image'/>

                                <h5 className='text text--sm'>
                                    <FormattedMessage id='articles_one_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='articles_one_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/how-return-taxes-border`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='articles_more' />
                                    <ArrowLong/>
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-two.jpg' alt='Articles Image'/>

                                <h5 className='text text--sm'><FormattedMessage id='articles_two_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='articles_two_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/what-crowdshipping-and-how-make-money-while-traveling`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='articles_more' />
                                    <ArrowLong/>
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='articles__item'>
                                <img src='/assets/images/articles-three.jpg' alt='Articles Image'/>

                                <h5 className='text text--sm'><FormattedMessage id='articles_three_title' /></h5>
                                <p className='text text--2sm'>
                                    <FormattedMessage id='articles_three_desc' />
                                </p>
                                <a
                                    href={`/${language}/post/5-reasons-order-abroad`}
                                    className='button button--text text text--2sm'
                                >
                                    <FormattedMessage id='articles_more' />
                                    <ArrowLong/>
                                </a>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <footer className='footer'>
                <div className='footer__container container'>
                    <div>
                        <a className='footer__logo' href='/'>
                        <Logo/>
                        </a>
                        {/*<div className="language-switcher-f">
                            <p className='text text--sm'><FormattedMessage id='language_placeholder' />:</p>
                            <select value={useAppSelector(state => state.LanguageReducer.value)} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>
                                <option value='en'>English</option>
                                <option value='ru'>Russian</option>
                            </select>
                        </div>*/}
                    </div>
                    <div className='footer__content'>
                        <div className='footer__contacts'>
                            <h5 className='text text--2md'><FormattedMessage id='contacts' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>Email</p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:info@weareway.com'
                                >
                                    info@weareway.com
                                </a>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='phone_number' /></p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:+442037696167'
                                >
                                    +442037696167
                                </a>
                            </div>
                        </div>
                        {/*<div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='middle_east_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='middle_east_address1' /><br/>
                                    <FormattedMessage id='middle_east_address2' /><br/>
                                    <FormattedMessage id='middle_east_address3' /><br/>
                                    <FormattedMessage id='middle_east_address4' /><br/>
                                    <FormattedMessage id='middle_east_address5' /><br/>
                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='middle_east_country' /></p>
                            </div>
                        </div>*/} 
                        <div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='european_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='european_address1' /><br/>
                                    <FormattedMessage id='european_address2' /><br/>
                                    <FormattedMessage id='european_address3' /><br/>
                                    {/*<FormattedMessage id='european_address4' /><br/>*/}

                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='european_country' /></p>
                            </div>
                        </div>
                        {/*<div className='footer__legal'>
                            <h5 className='text text--2md'><FormattedMessage id='legal_documents' /></h5>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/term-use-weareway`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='term_use' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/privacy-policy`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='privacy_policy' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    className='footer__link text text--sm'
                                    href={`/${language}/terms/use-cookies`}
                                >
                                    <FormattedMessage id='use_cookies' />
                                </a>
                            </div>
                        </div>*/}
                    </div>
                </div>
                <div className='footer__copyright container'>
                    <p className='text text--2sm'>
                        © {new Date().getFullYear()} WEAREWAY LTD. <FormattedMessage id='right_reserved' />
                    </p>
                    {/*<div className='footer__social'>
                        {' '}
                        <a
                            className='footer__social-button'
                            href='https://www.youtube.com/channel/UCBX_dsxetYMozv9jFjmU9gA'
                        >
                            <Youtube/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://instagram.com/we_are_way?igshid=YmMyMTA2M2Y='
                        >
                            <Instagram/>
                        </a>
                        <a className='footer__social-button' href='https://t.me/we_are_way'>
                            <Telegram/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://www.facebook.com/weareway2022'
                        >
                            <Facebook/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://twitter.com/weareway'
                        >
                            <Twitter/>
                        </a>
                    </div>*/}
                </div>
            </footer>
            { !isLoading && save[popupLoadCard] !== undefined &&
                <div className={'popup' + ' ' + (activePopup && 'is-open')}>
                    <div className='popup__blur'></div>
                    <div ref={popupWindowRef} className='popup__action'>
                        <button
                            disabled={popupLoadCard === 0}
                            onClick={() => setPopupLoadCard((prev: any) => prev - 1)}
                            className='popup__arrow popup__arrow--prev'
                        >
                            <ArrowRight/>
                        </button>
                        <div className='popup__window'>
                            <div className='popup__container'>
                                <div className='popup__header'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__back'
                                    >
                                        <ArrowBack/>
                                    </button>
                                    <h5 className='text text--2md'>
                                        {Parser(save[popupLoadCard].field_product_name)}
                                    </h5>
                                </div>
                                <div className='popup__control'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__close'
                                    >
                                        <CloseIcon/>
                                    </button>
                                </div>
                                <div className='popup__content'>
                                    <div className='popup__card-info'>
                                        <img
                                            className='popup__product-image'
                                            src={`https://app.weareway.com${save[popupLoadCard].field_description_images !== undefined && save[popupLoadCard].field_description_images.split(',')[0]}`}
                                            alt='Product'
                                        />

                                        <div className='popup__info'>
                                            <h5 className='text text--4lg'>
                                                {Parser(save[popupLoadCard].field_product_name)}
                                            </h5>
                                            <div className='popup__new-price'>
                                                <p className='text text--5lg'>

                                                    $ {save[popupLoadCard].field_discount_price}
                                                </p>
                                                <p className='popup__country text text--sm'>
                                                    {save[popupLoadCard].field_country}
                                                </p>
                                            </div>
                                            <p className='popup__caption text text--xsm'>
                                                <FormattedMessage id='price_already' />
                                            </p>
                                            <div className='popup__old-price'>
											<span className='text text--sm'>
												$ {save[popupLoadCard].field_basic_price}
											</span>
                                                <p className='text text--sm'><FormattedMessage id='original_price' /></p>
                                            </div>
                                            <div className='popup__actions'>
                                                <div className='popup__discount'>
                                                    <h5 className='text text--3lg'>
                                                        {-1 *
                                                            percent(
                                                                save[popupLoadCard].field_discount_price,
                                                                save[popupLoadCard].field_basic_price
                                                            )}
                                                        %
                                                    </h5>
                                                    <p className='text text--2xsm'><FormattedMessage id='can_save' /></p>
                                                </div>
                                                <a href={`/${language}/user/landing?create=product&secret_id=${save[popupLoadCard].nid}`}
                                                   className='popup__order button button--primary button--lg'>
                                                    <FormattedMessage id='order' />
                                                </a>
                                            </div>
                                            <a
                                                className='popup__shop button button--text'
                                                href={save[popupLoadCard].field_shop_url}
                                            >
                                                <ExternalLink/>
                                                <FormattedMessage id='where_buy' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__description'>
                                        <h5 className='text text--2sm'><FormattedMessage id='product_description' /></h5>
                                        {save[popupLoadCard].field_description_text ? (<>
                                            <p
                                                className={
                                                    'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                                }
                                            >
                                                {Parser(save[popupLoadCard].field_description_text)}
                                            </p>
                                            <a
                                                onClick={() => {
                                                    setIsOpenMore(prev => !prev)
                                                }}
                                                className='button text text--2sm'
                                            >
                                                <FormattedMessage id='more' />
                                                <ArrowRight/>
                                            </a></>) : <p
                                            className={
                                                'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                            }
                                        >
                                            No description
                                        </p>
                                        }
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__details'>
                                        <h5 className='text text--sm'><FormattedMessage id='details' /></h5>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_name' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_name)}
                                            </p>
                                        </div>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_address' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_address_term)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={popupLoadCard === save.length - 1}
                            onClick={() => setPopupLoadCard((prev: any) => prev + 1)}
                            className='popup__arrow popup__arrow--next'
                        >
                            <ArrowRight/>
                        </button>
                    </div>
                </div>
            }

            {/* <div className={`popup popup--list ${activeSecondPopup && 'is-open'}`}>
                <div className='popup__blur'></div>
                <div className='popup__window'>
                    <div className='popup__container'>
                        <div className='popup__header'>
                            <button
                                onClick={() => setActiveSecondPopup(false)}
                                className='popup__back'
                            >
                                <ArrowBack/>
                            </button>
                            <h5 className='text text--2md'>
                                {activeMode === 0 ? 'Find an traveler' : 'Find an order to deliver '}
                            </h5>
                        </div>
                        <div className='popup__control'>
                            <h5 className='text text--2lg'>{activeMode === 0 ? 'Find an traveler' : 'Find an order to deliver '}</h5>
                            <button
                                onClick={() => setActiveSecondPopup(false)}
                                className='popup__close'
                            >
                                <CloseIcon/>
                            </button>
                        </div>
                        <div className='popup__content'>
                            <div className="popup__inputs">
                                <div className='search__input'>
                                    <div className='search__input-info'>
                                        <div className='search__input-info-item'>
                                            <p className='text text--2sm'>From</p>
                                        </div>
                                    </div>
                                    <div className='search__input-content'>
                                        <Flight/>

                                        <Autocomplete
                                            ref={destinationRef}
                                            className='text text--sm'
                                            placeholder='Enter destination point'
                                            apiKey={'AIzaSyDt6omtAIAbd8yWL4DKmTDE26IcBBGLh7M'}
                                            onPlaceSelected={place => {
                                                searchButtonRef.current?.classList.remove('disabled')
                                                setDestinationAddress(place.formatted_address)

                                            }}
                                            onChange={() => {
                                                if (destinationRef.current !== null) {

                                                    if (destinationRef.current.value.length > 0) {
                                                        setDestinationAddress(destinationRef.current.value)
                                                        searchButtonRef.current && searchButtonRef.current.classList.remove('disabled')
                                                    } else if ((arrivalRef.current && arrivalRef.current.value.length < 1) && (destinationRef.current && destinationRef.current.value.length < 1)) {
                                                        setDestinationAddress(destinationRef.current.value)
                                                        searchButtonRef.current && searchButtonRef.current.classList.add('disabled')
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='search__input'>
                                    <div className='search__input-info'>
                                        <div className='search__input-info-item'>
                                            <p className='text text--2sm'>To</p>
                                        </div>
                                    </div>
                                    <div className='search__input-content'>
                                        <Arrival/>

                                        <Autocomplete
                                            ref={arrivalRef}
                                            className='text text--sm'
                                            placeholder='Enter arrival point'
                                            apiKey={'AIzaSyDt6omtAIAbd8yWL4DKmTDE26IcBBGLh7M&language=en'}
                                            onPlaceSelected={place => {
                                                searchButtonRef.current?.classList.remove('disabled')
                                                setArrivalAddress(place.formatted_address)

                                            }}
                                            onChange={() => {
                                                if (arrivalRef.current !== null) {
                                                    if (arrivalRef.current.value.length > 0) {
                                                        setArrivalAddress(arrivalRef.current.value)
                                                        searchButtonRef.current && searchButtonRef.current.classList.remove('disabled')
                                                    } else if ((arrivalRef.current && arrivalRef.current.value.length < 1) && (destinationRef.current && destinationRef.current.value.length < 1)) {
                                                        setArrivalAddress(arrivalRef.current.value)
                                                        searchButtonRef.current && searchButtonRef.current.classList.add('disabled')
                                                    }
                                                }
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className='search__buttons'>
                                    <Link
                                        to={
                                            activeMode === 1
                                                ? `/user/landing?create=voyage_connect&from=${destinationAddress}&to=${arrivalAddress}`
                                                : `/user/landing?create=order_connect&from=${destinationAddress}&to=${arrivalAddress}`
                                        }
                                        ref={searchButtonRef}
                                        className='search__button button button--primary disabled'
                                    >
                                        Search
                                    </Link>
                                </div>
                            </div>
                            <h5 className='text text--3lg'>{activeMode === 0 ? 'Travelers' : 'Orders'}</h5>
                            {activeMode == 0 ? <><Swiper spaceBetween={16} slidesPerView={'auto'} className="popup__cards popup__cards--traveler">
                                <SwiperSlide className="popup__card-item card-item--traveler">
                                    <div className="card-item__traveler">
                                        <img src="assets/images/app.webp" alt="App"/>
                                        <div className="card-item__traveler-info">
                                            <h5 className="text text--2lg">Robert</h5>
                                            <div className="card-item__rating">
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star_half.svg" alt="Star"/>
                                                <img src="assets/images/star_empty.svg" alt="Star"/>
                                            </div>
                                            <p className="text text--2sm">Traveled 5 times</p>
                                        </div>
                                    </div>
                                    <div className="card-item__points">
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/usa.svg" alt="Flag"/>
                                            <p className="text text--sm">USA</p>
                                            <span className="text text--2sm">02.12.2022</span>
                                        </div>
                                        <div className="card-item__placeholder">
                                            <img src="assets/images/lined.svg" alt=""/>
                                        </div>
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/uk.svg" alt="Flag"/>
                                            <p className="text text--sm">Portugal</p>
                                            <span className="text text--2sm">03.12.2022</span>
                                        </div>
                                    </div>
                                    <a href="#" className="button button--secondary ">Connect</a>

                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item--traveler">
                                    <div className="card-item__traveler">
                                        <img src="assets/images/app.webp" alt="App"/>
                                        <div className="card-item__traveler-info">
                                            <h5 className="text text--2lg">Robert</h5>
                                            <div className="card-item__rating">
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star_half.svg" alt="Star"/>
                                                <img src="assets/images/star_empty.svg" alt="Star"/>
                                            </div>
                                            <p className="text text--2sm">Traveled 5 times</p>
                                        </div>
                                    </div>
                                    <div className="card-item__points">
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/usa.svg" alt="Flag"/>
                                            <p className="text text--sm">USA</p>
                                            <span className="text text--2sm">02.12.2022</span>
                                        </div>
                                        <div className="card-item__placeholder">
                                            <img src="assets/images/lined.svg" alt=""/>
                                        </div>
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/uk.svg" alt="Flag"/>
                                            <p className="text text--sm">Portugal</p>
                                            <span className="text text--2sm">03.12.2022</span>
                                        </div>
                                    </div>
                                    <a href="#" className="button button--secondary ">Connect</a>

                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item--traveler">
                                    <div className="card-item__traveler">
                                        <img src="assets/images/app.webp" alt="App"/>
                                        <div className="card-item__traveler-info">
                                            <h5 className="text text--2lg">Robert</h5>
                                            <div className="card-item__rating">
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star_half.svg" alt="Star"/>
                                                <img src="assets/images/star_empty.svg" alt="Star"/>
                                            </div>
                                            <p className="text text--2sm">Traveled 5 times</p>
                                        </div>
                                    </div>
                                    <div className="card-item__points">
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/usa.svg" alt="Flag"/>
                                            <p className="text text--sm">USA</p>
                                            <span className="text text--2sm">02.12.2022</span>
                                        </div>
                                        <div className="card-item__placeholder">
                                            <img src="assets/images/lined.svg" alt=""/>
                                        </div>
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/uk.svg" alt="Flag"/>
                                            <p className="text text--sm">Portugal</p>
                                            <span className="text text--2sm">03.12.2022</span>
                                        </div>
                                    </div>
                                    <a href="#" className="button button--secondary ">Connect</a>

                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item--traveler">
                                    <div className="card-item__traveler">
                                        <img src="assets/images/app.webp" alt="App"/>
                                        <div className="card-item__traveler-info">
                                            <h5 className="text text--2lg">Robert</h5>
                                            <div className="card-item__rating">
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star.svg" alt="Star"/>
                                                <img src="assets/images/star_half.svg" alt="Star"/>
                                                <img src="assets/images/star_empty.svg" alt="Star"/>
                                            </div>
                                            <p className="text text--2sm">Traveled 5 times</p>
                                        </div>
                                    </div>
                                    <div className="card-item__points">
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/usa.svg" alt="Flag"/>
                                            <p className="text text--sm">USA</p>
                                            <span className="text text--2sm">02.12.2022</span>
                                        </div>
                                        <div className="card-item__placeholder">
                                            <img src="assets/images/lined.svg" alt=""/>
                                        </div>
                                        <div className="card-item__path">
                                            <img src="assets/images/flags/uk.svg" alt="Flag"/>
                                            <p className="text text--sm">Portugal</p>
                                            <span className="text text--2sm">03.12.2022</span>
                                        </div>
                                    </div>
                                    <a href="#" className="button button--secondary ">Connect</a>

                                </SwiperSlide>

                            </Swiper>
                                <div className="popup__controls">
                                    <a href="" className="button button--primary">Create voyage</a>
                                </div>
                            </> : <><Swiper spaceBetween={16} slidesPerView={'auto'} className="popup__cards">
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="popup__card-item card-item">
                                    <img src="assets/images/app.webp" alt="App"/>
                                    <div className="card-item__content">
                                        <h5 className='text text--2md'>Air Jordan 4 retro SE 95 Red</h5>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">from</span>
                                            <p className="text text--2sm">Germany</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">to</span>
                                            <p className="text text--2sm">United Kingdom</p>
                                        </div>
                                        <div className='card-item__item'>
                                            <span className="text text--2sm">deliver before</span>
                                            <p className="text text--2sm">02.12.2022</p>
                                        </div>
                                        <a href="#" className="button button--secondary ">Deliver</a>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                                <div className="popup__controls">
                                    {activeMode === 0 ? (<a href="" className="button button--primary">Create order</a>) : (<a href="" className="button button--primary">Create voyage</a>)}

                                </div></>}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Root



