import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider, useSelector} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./routes/root";


import {setupStore} from "./store";


import './assets/styles/index.scss?v_c=123';
import Customer from "./routes/customer";
import Traveler from "./routes/traveler";
import {IntlProvider} from "react-intl";
import {messages} from "./translate/rootTranslate";
import {LOCALES} from "./translate/locales";
import {messagesC} from "./translate/customerTranslate";
import {messagesT} from "./translate/travelerTranslate";
import {useAppSelector} from "./hooks";


const store = setupStore()

const root = ReactDOM.createRoot(
    document.querySelector('.wrapper') as HTMLElement
);


const App = () => {
    let language = (navigator.language == 'ru' || navigator.language == 'ru-RU') ? 'ru' : 'en'

    let languages = useAppSelector(state=>state.LanguageReducer.value)


    const router = createBrowserRouter([
        {
            path: '/',
            element: <IntlProvider  messages={messages[languages]} locale={languages} ><Root/></IntlProvider>
        },
        {
            path: '/customer',
            element: <IntlProvider messages={messagesC[languages]} locale={languages} ><Customer /></IntlProvider>
        },
        {
            path: '/traveler',
            element: <IntlProvider messages={messagesT[languages]} locale={languages} ><Traveler/></IntlProvider>
        }
    ])


    return (
        <RouterProvider router={router}/>
    );
};

export default App;



root.render(
    <React.StrictMode>
        <Provider store={store}>
                <App/>
        </Provider>
    </React.StrictMode>
);

