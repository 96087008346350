
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IArticle} from "../../models/IArticle";
interface StepState {
    order: IArticle[],
    voyage: IArticle[],
    isLoading: boolean,
    error: string
}

const initialState: StepState = {
    order: [
        {
            id: 1,
            title: 'Specify the product',
            description:
                'Order creation is completely for free. The number of orders is unlimited',
        },
        {
            id: 2,
            title: 'Receive offers',
            description:
                'Review travel details and offers, and choose the best price and the most convenient dates.',
        },
        {
            id: 3,
            title: 'Make a deal',
            description:
                'The deal is secure as payment is held in the system until successful completion.',
        },

        {
            id: 4,
            title: 'Get your order',
            description: 'Enjoy receiving the orders in the most convenient way!',
        },
    ],
    voyage: [
        {
            id: 1,
            title: 'Specify the route',
            description:
                'Voyage creation is completely for free. There is no limit for the number them',
        },
        {
            id: 2,
            title: 'Make offers',
            description: 'Connect with a customer, discuss the details of the deal',
        },
        {
            id: 3,
            title: 'Make a deal',
            description:
                'The deal is secure as payment is held in the system until successful completion.',
        },

        {
            id: 4,
            title: 'Get your profit',
            description: 'Make money while traveling and fly even more often',
        },
    ],
    isLoading: false,
    error: ''
}

export const stepSlice = createSlice({
    name: 'save',
    initialState,
    reducers: {

    }
})

export default stepSlice.reducer