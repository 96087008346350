import ContentLoader from "react-content-loader";

const Skeleton = () => {
    return (
        <ContentLoader
            speed={2}
            width={242}
            height={391}
            viewBox="0 0 242 391"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"

        >
            <rect x="0" y="7" rx="8" ry="8" width="242" height="242" />
            <rect x="0" y="257" rx="8" ry="8" width="78" height="32" />
            <rect x="0" y="320" rx="8" ry="8" width="30" height="18" />
            <rect x="-1" y="343" rx="8" ry="8" width="242" height="48" />
            <rect x="85" y="257" rx="8" ry="8" width="78" height="32" />
            <rect x="0" y="297" rx="8" ry="8" width="242" height="21" />
        </ContentLoader>
    )
}
export default Skeleton