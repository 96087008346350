import {combineReducers, configureStore} from "@reduxjs/toolkit";
import SaveReducer from './reducers/SaveSlice'
import SaveTopReducer from './reducers/SaveTopSlice'
import StepReducer from './reducers/StepSlice'
import FaqReducer from './reducers/FaqSlice'
import AuthReducer from "./reducers/AuthSlice";
import LanguageReducer from './reducers/Language';

const rootReducer = combineReducers({
    SaveReducer,
    StepReducer,
    FaqReducer,
    SaveTopReducer,
    AuthReducer,
    LanguageReducer

})
export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,

    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']