
import React from 'react'
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg'

interface IProps {
    title: React.ReactNode
    description: React.ReactNode
}

const FaqItem = (props: IProps) => {
    const [toggleElement, setToggleElement] = React.useState(false)

    return (
        <div
            className={'faq-alt__item' + ' ' + (toggleElement && 'is-open')}
            onClick={() => setToggleElement(prev => !prev)}
        >
            <div className='faq-alt__top'>
                <h5 className='text text--2md'>{props.title}</h5>
                <ArrowRight />
            </div>
            <div className='faq-alt__bottom'>
                <p className='text text--sm'>{props.description}</p>
            </div>
        </div>
    )
}

export default FaqItem
