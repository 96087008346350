import {LOCALES} from "./locales";

export const messagesC = {
    [LOCALES.ENGLISH] : {
        language_placeholder: 'Language',
        go_to_site: 'Go to site',
        log_in: `Log In`,
        everything_wish: 'Everything you wish for',
        can_buy: 'YOU CAN BUY',
        branding: `Create an order and we will connect you with someone who will deliver it`,
        learn_more: `Learn more`,
        best_seller: `Best Sellers`,
        see_more: `See more`,
        best_seller_desc: `Find out how much you can save by ordering from other countries`,
        price_already: `The price already includes platform expenses`,
        dont_miss: `Don’t miss your opportunity`,
        notification_desc: `Receive notifications about travelers heading in your direction from different countries`,
        notification_placeholder: `Enter your email`,
        notification_button: `Sign In`,
        unique_product_title: `Unique products`,
        unique_product_desc: `Not only save money, but have the opportunity of ordering items unavailable in your country`,
        collectors_title: `Collectors`,
        add_to_cart: `Add to cart `,
        food: `Food`,
        cosmetics: `Cosmetics`,
        clothing: `Clothing`,
        how_works: `How it works`,
        step_title: `A few simple steps to the desired delivery`,
        step_one_title: `Specify the product`,
        step_one_desc: `Order creation is completely for free. The number of orders is unlimited`,
        step_two_title: `Receive offers`,
        step_two_desc: `Review travel details and offers, and choose the best price and the most convenient dates.`,
        step_three_title: `Receive offers`,
        step_three_desc: `The deal is as secure as payment is held in the system untill succesful completion.`,
        step_four_title: `Get your order`,
        step_four_desc: `Enjoy receiving the orders in the most convenient way!`,
        start_saving: `Start saving now`,
        register_user: `registered users`,
        voyage: `voyages`,
        orders: `orders`,
        app: `WeAreWay app`,
        app_desc: `The app is even more convenient`,
        faq: `Frequently asked questions`,
        faq_desc: `We are ready to answer any questions`,
        faq_one_title: `Who can become a customer?`,
        faq_one_desc: `It doesn't matter where you live - you can get the goods you want from anywhere in the world to your destination of choice. With WeAreWay you choose what you want and pay much less than using popular sites and services.`,
        faq_two_title: `Who can become a customer?`,
        faq_two_desc: `"The number of deals with travelers is not limited. You can order as many items as you wish and choose one person to deliver all of them or make a deal with different travelers.`,
        faq_three_title: `What are the guarantees of deal security?`,
        faq_three_desc: `A traveler will receive payment just after the successful completion of the deal. The money is just reserved in the bank account after agreement and can be refunded in case of failed deal. We are always in touch to resolve any issues that may arise.`,
        direction_title: `Directions`,
        direction_desc: `Ready for an adventure?`,
        start_shopping: `Start shopping`,
        mexico: `Mexico`,
        usa: `USA`,
        uk: `UK`,
        germany: `Germany`,
        turkey: `Turkey`,
        contacts: `Contacts`,
        middle_east_office: `Middle East Office`,
        european_office: `European Office`,
        legal_documents: `Legal documents`,
        phone_number: `Phone`,
        middle_east_address1: `Amot`,
        middle_east_address2: `Atrium Tower`,
        middle_east_address3: `2 Jabotinsky Street`,
        middle_east_address4: `Ramat Gan`,
        middle_east_address5: `5250501`,
        middle_east_country: `Israel`,
        european_address1: `20 Hammersmith Broadway`,
        european_address2: `London`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `England`,
        term_use: `Terms of Use`,
        privacy_policy: `Privacy Policy`,
        use_cookies: `Use of Cookies`,
        right_reserved: `All rights reserved.`,

        product_description: `Product description`,
        original_price: `Original price`,
        can_save: `You can save`,
        order: 'Order',
        where_buy: `Where to buy`,
        details: `Details`,
        shop_name: `Shop name`,
        shop_address: `Shop address`,
        more: `More`,
    },
    [LOCALES.RUSSIAN]: {
        language_placeholder: 'Язык',
        go_to_site: 'Перейти на сайт',
        log_in: `Войти`,
        everything_wish: ' ',
        can_buy: 'КУПИ ВСЕ ЧТО ЗАХОЧЕШЬ',
        branding: `Создайте заказ, и мы свяжем вас с тем, кто его доставит`,
        learn_more: `Узнать больше`,
        best_seller: `Самые популярные товары`,
        see_more: `Узнать больше`,
        best_seller_desc: `Узнайте, сколько вы можете сэкономить, заказывая из других стран`,
        price_already: `Услуги сервиса включены в стоимость`,
        dont_miss: `Не упустите шанс`,
        notification_desc: `Получайте уведомления о путешественниках, направляющихся к вам из другой страны`,
        notification_placeholder: `Введите ваш email`,
        notification_button: `Войти`,
        unique_product_title: `Эксклюзивные товары`,
        unique_product_desc: `Не только экономьте, но и заказывайте товары, которые недоступны в вашей стране`,
        collectors_title: `Для коллекционеров`,
        add_to_cart: `Добавить в корзину`,
        food: `Еда`,
        cosmetics: `Косметика`,
        clothing: `Одежда`,
        how_works: `Как это работает`,
        step_title: `Несколько простых шагов помогут тебе зарабатывать на путешествиях`,
        step_one_title: `Укажите товар`,
        step_one_desc: `Создание заказов совершенно бесплатно. Их количество не ограничено.`,
        step_two_title: `Получите предложение от путешественника`,
        step_two_desc: `Ознакомьтесь с информацией о поездках и выберите предложения по лучшей цене и удобной для вас дате.`,
        step_three_title: `Заключите сделку с путешественником`,
        step_three_desc: `Сделка безопасна, так как платеж удерживается в системе до успешного его завершения.`,
        step_four_title: `Получите свой заказ`,
        step_four_desc: `Наслаждайтесь привезенным заказом!`,
        start_saving: `Начните экономить уже сейчас`,
        register_user: `пользователей`,
        voyage: `совершенных путешествий`,
        orders: `совершенных заказов`,
        app: `Скачайте приложение WeAreWay`,
        app_desc: `В приложении ещё удобнее`,
        faq: `Часто задаваемые вопросы`,
        faq_desc: `Мы готовы ответить на все вопросы`,
        faq_one_title: `Кто может стать заказчиком?`,
        faq_one_desc: `Неважно, кто где ты живешь — ты сможешь получить желаемый товар из любой точки мира в то место, где находишься. C WeAreWay ты сам выбираешь, что хочешь, и платишь гораздо меньше, чем если бы воспользовался популярными сайтами и сервисами.`,
        faq_two_title: `Есть ли ограничение на количество заказов, которые я могу создать?`,
        faq_two_desc: `Количество сделок с путешественниками не ограничено. Вы можете заказать любое количество товаров и выбрать одного путешественника, который их доставит или заключить сделку с разными путешественниками.`,
        faq_three_title: `Каковы гарантии безопасности сделки?`,
        faq_three_desc: `Путешественник получит оплату сразу после успешного завершения сделки. Деньги хранятся на банковском счете после соглашения и могут быть возвращены в случае неудавшейся сделки. Мы всегда на связи и готовы помочь.`,
        direction_title: `Направления`,
        direction_desc: `Готовы к приключениям?`,
        start_shopping: `Перейти к покупкам`,
        mexico: `Мексика`,
        usa: `США`,
        uk: `Британия`,
        germany: `Германия`,
        turkey: `Турция`,
        contacts: `Контактная информация`,
        middle_east_office: `Офис Ближнего Востока`,
        european_office: `Офис Европы`,
        legal_documents: `Нормативные документы`,
        phone_number: `Телефон`,
        middle_east_address1: `Амот`,
        middle_east_address2: `Атриум Тауэр`,
        middle_east_address3: `Улица Жаботинского, 2`,
        middle_east_address4: `Рамат-Ган`,
        middle_east_address5: `5250501`,
        middle_east_country: `Израиль`,
        european_address1: `20 Hammersmith Broadway`,
        european_address2: `Лондон`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `Англия`,
        term_use: `Условия использования`,
        privacy_policy: `Политика конфиденциальности`,
        use_cookies: `Использование куков`,
        right_reserved: `Все права защищены.`,
        product_description: `Описание товара`,
        original_price: `Изначальная цена`,
        can_save: `Экономия`,
        order: 'Заказать',
        where_buy: `Где купить`,
        details: `Подробности`,
        shop_name: `Название магазина`,
        shop_address: `Адрес магазина`,
        more: `Больше`,
    }
}