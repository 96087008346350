import {LOCALES} from "./locales";

export const messagesT = {
    [LOCALES.ENGLISH] : {
        language_placeholder: 'Language',
        go_to_site: 'Go to site',
        log_in: `Log In`,
        find: 'Find',
        your_way: `YOUR WAY`,
        to_earn: `To Earn`,
        while_traveling: `while traveling`,
        branding: `Deliver ordered items to other people from the cities and countries you visit.`,
        learn_how: `Learn how it works`,
        destination_point: `Enter destination point`,
        arrival_point: `Enter arrival point`,
        search: `SEARCH`,
        card_one: `Buy cheaper. Buy items  unavailable in your country`,
        card_two: `Cover your travel expenses`,
        card_three: `Full order prepayment guarantees traveler protection on delivery`,
        deliver_reward: `Delivery reward`,
        see_more: `See more`,
        deliver_desc: `Find out how much you can earn on the most popular items delivery`,
        earn_on_delivery: `earn on delivery`,
        how_works_title: `How it works`,
        how_works_desc: `A few simple steps will help you make money while traveling`,
        step_one_title: `Specify the route`,
        step_one_desc: `Voyage creation is completely for free. There is no limit for the number them`,
        step_two_title: `Make offers`,
        step_two_desc: `Connect with a customer, discuss the details of the deal`,
        step_three_title: `Make a deal`,
        step_three_desc: `The deal is secure as payment is held in the system until successful completion.`,
        step_four_title: `Get your profit`,
        step_four_desc: `Make money while traveling and fly even more often`,
        earning_now: `start earning now`,
        sign_in_title: `Sign In`,
        sign_in_desc: `Be the first to know about the most interesting and profitable orders!`,
        sign_in_placeholder: `Enter your email`,
        faq:`Frequently asked questions`,
        faq_desc: `We are ready to answer any questions`,
        faq_one_title: `Who can become a traveller?`,
        faq_one_desc: `This position is open to anyone who travels, flies, or travels in any other way around the world. Anyone can become a traveler - the main thing is to have a desire to earn money and some free space in your luggage.`,
        faq_two_title: `Is there a limit to how many deals I can make?`,
        faq_two_desc: `The number of deals with customers is not limited, the main thing is to assess your capabilities and the size of your luggage. It is better to think in advance for how many potential customers and what exactly you are going to bring.`,
        faq_three_title: `What are the guarantees of deal security?`,
        faq_three_desc: `After the successful completion of the deal, you will definitely receive payment. The money is reserved in the bank account immediately after agreement with the customer. We are always in touch to resolve any issues that may arise.`,
        app: `WeAreWay app`,
        app_desc: `The app is even more convenient`,
        article: `Helpful hints before a voyage`,
        article_one_title: `How do I get tax free?`,
        article_one_desc: `Shopping abroad is enjoyable, but sometimes too costly. Especially if you have not yet had time to start using the services of WeAreWay...`,
        article_two_title: `What is crowdshipping?`,
        article_two_desc: `It's expensive to travel. But it's not for everyone! And everyone can become a part of of the for a select few society...`,
        article_three_title: `Basic restrictions at customs`,
        article_three_desc: `Our world is so vast and each state has its own customs regulations. However, there are general rules that are important to follow if you are going to cross any border...`,
        learn_more: `Learn more`,
        direction_title: `Directions`,
        direction_desc: `Ready for an adventure?`,
        start_shopping: `Try now`,
        mexico: `Mexico`,
        usa: `USA`,
        uk: `UK`,
        germany: `Germany`,
        turkey: `Turkey`,
        contacts: `Contacts`,
        middle_east_office: `Middle East Office`,
        european_office: `European Office`,
        legal_documents: `Legal documents`,
        phone_number: `Phone`,
        middle_east_address1: `Amot`,
        middle_east_address2: `Atrium Tower`,
        middle_east_address3: `2 Jabotinsky Street`,
        middle_east_address4: `Ramat Gan`,
        middle_east_address5: `5250501`,
        middle_east_country: `Israel`,
        european_address1: `20 Hammersmith Broadway`,
        european_address2: `London`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `England`,
        term_use: `Terms of Use`,
        privacy_policy: `Privacy Policy`,
        use_cookies: `Use of Cookies`,
        right_reserved: `All rights reserved.`,
        price_already: `The price already includes platform expences`,
        product_description: `Product description`,
        original_price: `Original price`,
        can_save: `You can save`,
        order: 'Order',
        where_buy: `Where to buy`,
        details: `Details`,
        shop_name: `Shop name`,
        shop_address: `Shop address`,
        more: `More`,
    },
    [LOCALES.RUSSIAN]: {
        language_placeholder: 'Язык',
        go_to_site: 'Перейти на сайт',
        log_in: `Войти`,
        find: 'Нaйдите ',
        your_way: `СВОЙ ПУТЬ`,
        to_earn: `ЗАРАБАТЫВАЙТЕ`,
        while_traveling: `благодаря путешествиям`,
        branding: `Доставляйте товары людям из других городов и стран, которые вы посещаете.`,
        learn_how: `Узнать больше`,
        destination_point: `Введите страну и город отправления`,
        arrival_point: `Введите страну и город прибытия`,
        search: `НАЙТИ`,
        card_one: `Покупайте товары, которые недоступны в вашей стране за выгодную цену`,
        card_two: `Покройте свои дорожные расходы`,
        card_three: `Полная предоплата заказа при доставке гарантирует защиту путешественнику `,
        deliver_reward: `Вознаграждение за доставку`,
        see_more: `Перейти в каталог`,
        deliver_desc: `Узнайте, сколько можно заработать на доставке самых популярных товаров`,
        earn_on_delivery: `Заработайте на доставке`,
        how_works_title: `Как это работает`,
        how_works_desc: `Несколько простых шагов помогут вам заработать благодаря путешествиям  `,
        step_one_title: `Укажите маршрут путешествия`,
        step_one_desc: `Создание путешествий совершенно бесплатно. Их количество не ограничено.`,
        step_two_title: `Предложите сделку`,
        step_two_desc: `Свяжитесь с заказчиком и обсудите с ним детали сделки.`,
        step_three_title: `Заключите сделку`,
        step_three_desc: `Сделка безопасна, так как платеж удерживается в системе до успешного его завершения.`,
        step_four_title: `Получите свою прибыль`,
        step_four_desc: `Зарабатывайте в путешествиях и летайте еще чаще.`,
        earning_now: `Начать зарабатывать`,
        sign_in_title: `Вход`,
        sign_in_desc: `Узнавайте первыми о самых интересных и выгодных заказах!`,
        sign_in_placeholder: `Введите ваш email`,
        faq:`Часто задаваемые вопросы`,
        faq_desc: `Мы готовы ответить на любые вопросы`,
        faq_one_title: `Кто может стать путешественником?`,
        faq_one_desc: `Эта возможность открыта для всех, кто путешествует любым  способом по всему миру. Путешественником может стать каждый – главное, чтобы было желание заработать и немного свободного места в багаже.`,
        faq_two_title: `Есть ли ограничение на количество сделок, которые я могу совершить?`,
        faq_two_desc: `Количество сделок с клиентами не ограничено, главное оценить свои возможности и размер багажа. Лучше заранее продумать, сколько потенциальных клиентов и что именно вы собираетесь привести.`,
        faq_three_title: `Каковы гарантии безопасности сделки?`,
        faq_three_desc: `После успешного завершения сделки вы обязательно получите оплату. Деньги резервируются на банковском счете сразу после согласования с заказчиком. Мы всегда на связи для решения любых возникающих вопросов.`,
        app: `Скачайте приложение WeAreWay`,
        app_desc: `В приложении ещё удобнее`,
        article: `Полезные советы перед путешествием`,
        article_one_title: `Как получить возврат налога на границе?`,
        article_one_desc: `Покупки за границей дело приятное, но иногда затратно. Особенно если вы еще не успели начать пользоваться услугами WeAreWay...`,
        article_two_title: `Что такое краудшиппинг?`,
        article_two_desc: `Путешествовать дорого. Но не для всех! И каждый может стать частью сообщества “не для всех”...`,
        article_three_title: `Основные ограничения на таможнях`,
        article_three_desc: `Наш мир настолько большой и каждое государство имеет свои таможенные правила. Тем не менее, есть общие правила, которые важно соблюдать, если вы собираетесь пересекать какую-либо границу...`,
        learn_more: `Узнать больше`,
        direction_title: `Направления`,
        direction_desc: `Готовы к приключениям?`,
        start_shopping: `Попробуйте сейчас`,
        mexico: `Мексика`,
        usa: `США`,
        uk: `Британия`,
        germany: `Германия`,
        turkey: `Турция`,
        contacts: `Контактная информация`,
        middle_east_office: `Офис Ближнего Востока`,
        european_office: `Офис Европы`,
        legal_documents: `Нормативные документы`,
        phone_number: `Телефон`,
        middle_east_address1: `Амот`,
        middle_east_address2: `Атриум Тауэр`,
        middle_east_address3: `Улица Жаботинского, 2`,
        middle_east_address4: `Рамат-Ган`,
        middle_east_address5: `5250501`,
        middle_east_country: `Израиль`,
        european_address1: `20 Hammersmith Broadway`,
        european_address2: `Лондон`,
        european_address3: `W6 7AB`,
        european_address4: ``,
        european_country: `Англия`,
        term_use: `Условия использования`,
        privacy_policy: `Политика конфиденциальности`,
        use_cookies: `Использование куков`,
        right_reserved: `Все права защищены.`,
        price_already: `Услуги сервиса включены в стоимость`,
        product_description: `Описание товара`,
        original_price: `Изначальная цена`,
        can_save: `Экономия`,
        order: 'Заказать',
        where_buy: `Где купить`,
        details: `Подробности`,
        shop_name: `Название магазина`,
        shop_address: `Адрес магазина`,
        more: `Больше`,
    }
}