import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AuthState{
    auth: boolean,
    isLoading: boolean,
    error: string
}

const initialState: AuthState = {
    auth: false,
    isLoading: false,
    error: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authFetching(state){
            state.isLoading = true;
        },
        authFetchingSuccess(state, action: PayloadAction<boolean>){
            state.isLoading = false;
            state.error = ''
            state.auth = action.payload
        },
        authFetchingError(state, action: PayloadAction<string>){
            state.isLoading = false;
            state.error = action.payload
        },
    }
})

export default authSlice.reducer