
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IArticle} from "../../models/IArticle";
interface FaqState {
    faq: IArticle[],

    isLoading: boolean,
    error: string
}

const initialState: FaqState = {

    faq: [
        {
            id: 1,
            title: 'How do I order what I need?',
            description:
                'You need to register or log into the system. Create new order and expect a traveller to pick up it. You will be notified when the traveller shows up.',
        },
        {
            id: 2,
            title: 'How do I pick up the order?',
            description:
                'Log in or create а new account and add your flight. Choose any product you agree to buy and deliver from the offered list, discuss all the details with the customer in a private chat. And you are good to go.',
        },
        {
            id: 3,
            title: 'What are the guarantees of the deal?',
            description:
                'Our system is build on trust between a customer and a traveller. We have also created our own unique rating system to choose carefully people you can rely on. We guarantee that every order will be accompanied by a support specialist and in case of complicated situations the problem will be resolved in the most convenient way for both parties.',
        },
        {
            id: 4,
            title: 'How can I track my order?',
            description:
                'After your order is picked up by a traveller, you will be able to communicate with him in a privat chat and discuss all the details regarding the delivery',
        },
    ],
    isLoading: false,
    error: ''
}

export const faqSlice = createSlice({
    name: 'save',
    initialState,
    reducers: {

    }
})

export default faqSlice.reducer