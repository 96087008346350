import {AppDispatch} from "../index";
import axios from "axios";


import {saveSlice} from "./SaveSlice";
import {saveTopSlice} from "./SaveTopSlice";
import {authSlice} from "./AuthSlice";


export const fetchSave = (urlFetch:string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(saveSlice.actions.saveFetching())
        const response = await axios.get<any[]>(urlFetch)
        dispatch(saveSlice.actions.saveFetchingSuccess(response.data))
    }catch (e:any) {
        dispatch(saveSlice.actions.saveFetchingError(e.message))
    }
}

export const fetchTopSave = (urlFetch:string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(saveTopSlice.actions.saveFetching())
        const response = await axios.get<any[]>(urlFetch)
        dispatch(saveTopSlice.actions.saveFetchingSuccess(response.data))
    }catch (e:any) {
        dispatch(saveTopSlice.actions.saveFetchingError(e.message))
    }
}

export const fetchAuth = () => async (dispatch:AppDispatch) =>{
    try {
        dispatch(authSlice.actions.authFetching())
        const response = await axios.get<any[]>('https://app.weareway.com/en/get/auth-user', {withCredentials: true})
        if (response.data.length > 0) {
            dispatch(authSlice.actions.authFetchingSuccess(true))
        }else{
            dispatch(authSlice.actions.authFetchingSuccess(false))
        }
    }catch (e:any) {
        dispatch(authSlice.actions.authFetchingError(e.message))
    }
}