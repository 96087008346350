import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: any = {
    value: 'en'
};

export const languageSlice = createSlice( {
    name: 'language',
    initialState,
    reducers: {
        changeLanguage(state, action: PayloadAction<string>){
            state.value = action.payload;
        }
    }
})

export default languageSlice.reducer