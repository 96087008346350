
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
interface SaveState {
    save: any[],
    isLoading: boolean,
    error: string
}

const initialState: SaveState = {
    save: [

    ],
    isLoading: false,
    error: ''
}

export const saveSlice = createSlice({
    name: 'save',
    initialState,
    reducers: {
        saveFetching(state){
            state.isLoading = true;
        },
        saveFetchingSuccess(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.error = ''
            state.save = action.payload
        },
        saveFetchingError(state, action: PayloadAction<string>){
            state.isLoading = false;
            state.error = action.payload
        },
    }
})

export default saveSlice.reducer