
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
interface SaveTopState {
    saveTop: any[],
    isLoadingTop: boolean,
    errorTop: string
}

const initialState: SaveTopState = {
    saveTop: [],
    isLoadingTop: false,
    errorTop: ''
}

export const saveTopSlice = createSlice({
    name: 'saveTop',
    initialState,
    reducers: {
        saveFetching(state){
            state.isLoadingTop = true;
        },
        saveFetchingSuccess(state, action: PayloadAction<any[]>){
            state.isLoadingTop = false;
            state.errorTop = ''
            state.saveTop = action.payload
        },
        saveFetchingError(state, action: PayloadAction<string>){
            state.isLoadingTop = false;
            state.errorTop = action.payload
        },
    }
})

export default saveTopSlice.reducer