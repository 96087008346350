import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchAuth, fetchSave, fetchTopSave} from "../store/reducers/ActionCreator";
import {Link} from "react-router-dom";
import Autocomplete from 'react-google-autocomplete'

import {ReactComponent as Logo} from '../assets/images/logo.svg'
import {ReactComponent as ArrowLong} from '../assets/images/arrow-long.svg'

import {ReactComponent as ArrowRight} from "../assets/images/arrow-right.svg";

import {ReactComponent as AppleIcon} from '../assets/images/apple.svg'
import {ReactComponent as GooglePlay} from '../assets/images/playmarket.svg'
import {ReactComponent as Youtube} from '../assets/images/youtube.svg'
import {ReactComponent as Instagram} from '../assets/images/instagram.svg'
import {ReactComponent as Telegram} from '../assets/images/telegram.svg'
import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'
import {ReactComponent as ArrowBack} from "../assets/images/back.svg";
import {ReactComponent as ExternalLink} from "../assets/images/external-link.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";

import {Swiper, SwiperSlide} from "swiper/react";
import {percent} from "../services/percent";
import {Navigation} from "swiper";
import Skeleton from "../components/skeleton/skeleton";
import {log} from "util";
import Parser from "html-react-parser";

import {FormattedMessage, useIntl} from "react-intl";
import {languageSlice} from "../store/reducers/Language";



const Root: React.FC = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {save, isLoading, error} = useAppSelector(state => state.SaveReducer)
    const {saveTop, isLoadingTop, errorTop} = useAppSelector(state => state.SaveTopReducer)
    const {order} = useAppSelector(state => state.StepReducer)
    const {auth} = useAppSelector((state) => state.AuthReducer)





    const saveNavigationPrevRef = React.useRef<HTMLButtonElement>(null)
    const saveNavigationNextRef = React.useRef<HTMLButtonElement>(null)
    const elementToScroll = React.useRef<HTMLDivElement>(null)
    const popupWindowRef = React.useRef<HTMLDivElement>(null)

    const emailInput = React.useRef<HTMLInputElement>(null)



    const [activePopup, setActivePopup] = React.useState(false)
    const [popupLoadCard, setPopupLoadCard] = React.useState(1)
    const [isOpenMore, setIsOpenMore] = React.useState(false)
    const [email, setEmail] = React.useState<string | null>('')
    const category = [{id: 7493, name:<FormattedMessage id='collectors_title' />}, {id: 7494, name:<FormattedMessage id='food' />}, {id: 7495, name:<FormattedMessage id='cosmetics' />}, {id: 7496, name:<FormattedMessage id='clothing' />}]

    const [activeCategory, setActiveCategory] = React.useState(7493)

    useEffect(() => {
        dispatch(fetchSave('https://app.weareway.com/en/get/secret/all?landing=customer'))
        dispatch(fetchTopSave('https://app.weareway.com/en/get/secret/all?landing=customer'))
        dispatch(fetchAuth())
        const handleClickOutside = (event: any) => {
            const path = event.path || (event.composedPath && event.composedPath())
            if (
                !path.includes(popupWindowRef.current) &&
                !event.target.closest('.save-card')
            ) {
                setActivePopup(false)
            }
        }
        document.body.addEventListener('click', handleClickOutside)

        return () => document.body.removeEventListener('click', handleClickOutside)
    }, [])

    let language = useAppSelector(state => state.LanguageReducer.value);


    return (
        <>

            <header className='header'>
                <div className='header__container container'>
                    <a className='header__logo' href='/'>
                        <Logo/>
                    </a>
                    <div>
                        {/*<div className="language-switcher-h">
                            <img src="/assets/images/translate.svg" alt="Translate"/>
                            <select value={language} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>

                                <option value='en'>En</option>
                                <option value='ru'>Ru</option>
                            </select>


                        </div>*/}
                        <a className='header__button button button--lg button--secondary button--fix-40'
                           href={auth ? `/${language}/connect`:`/${language}/user/login`}>{auth ? <FormattedMessage id='go_to_site' /> : <FormattedMessage id='log_in' />}</a>
                    </div>
                </div>
            </header>
            <section className='intro'>
                <div className='intro__content'>
                    <div className='intro__container container'>
                        <div className='intro__body'>
                            <div className='intro__slogan'>
                                <h5 className='intro__subtitle text'>
                                    <FormattedMessage id='everything_wish' />
                                </h5>
                                <h1 className='intro__title text'><FormattedMessage id='can_buy' /></h1>
                                <p className='intro__text text text--sm'>
                                    <FormattedMessage id='branding' />
                                </p>
                            </div>
                            <div className='intro__button'>
                                <button
                                    onClick={() =>
                                        elementToScroll.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center',
                                        })
                                    }
                                    className='button button--lg button--secondary'
                                >
                                    <FormattedMessage id='learn_more' />
                                    <ArrowLong />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='intro__image'>
                    <img src='/assets/images/intro-customer.jpg' alt='Intro' />
                </div>
            </section>
            <section className='save'>
                <div className='save__top container'>
                    <div className='save__title'>
                        <div className='save__flex-line'>
                            <h2 className='text text--2lg'><FormattedMessage id='best_seller' /></h2>
                            <a href={`/${language}/waw/save`} className='button button--text'>
                                <FormattedMessage id='see_more' />
                                <ArrowLong />
                            </a>
                        </div>
                        <p className='text text--sm'>
                            <FormattedMessage id='best_seller_desc' />
                        </p>
                    </div>
                    <div className='save__control'>
                        <button
                            ref={saveNavigationPrevRef}
                            className='save__button save__prev-slide'
                        >
                            <ArrowRight />
                        </button>
                        <button
                            ref={saveNavigationNextRef}
                            className='save__button save__next-slide'
                        >
                            <ArrowRight />
                        </button>
                    </div>
                </div>
                <div className='save__container container'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={'auto'}
                        navigation={{
                            prevEl: saveNavigationPrevRef.current
                                ? saveNavigationPrevRef.current
                                : undefined,
                            nextEl: saveNavigationNextRef.current
                                ? saveNavigationNextRef.current
                                : undefined,
                        }}
                        onBeforeInit={swiper => {
                            // @ts-ignore
                            swiper.params.navigation.prevEl = saveNavigationPrevRef?.current
                            // @ts-ignore
                            swiper.params.navigation.nextEl = saveNavigationNextRef?.current
                            swiper.navigation.init()
                        }}
                    >
                        { !isLoading && save !== undefined ?
                            save.map((elem, key) => (
                                <SwiperSlide
                                    key={key}
                                    onClick={() => {
                                        setPopupLoadCard(key)
                                        setActivePopup(true)
                                    }}
                                >
                                    <div className='save-card save-card--main'>
                                        <div className='save-card__top'>
                                            <img
                                                className='save-card__image'
                                                src={`https://app.weareway.com${elem.field_description_images !== undefined ? elem.field_description_images.split(',')[0]: '/sites/default/files/product.svg'}`}
                                                alt='Product photo'
                                            />

                                            <div className='save-card__discount'>
                                                <div className='save-card__discount-text text text--2sm'>
                                                    {percent(
                                                        elem.field_discount_price,
                                                        elem.field_basic_price
                                                    )}
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        <div className='save-card__middle'>
                                            <div className='save-card__price'>
                                                <p className='text text--4lg save-card__new-price'>
                                                    $ {elem.field_discount_price}
                                                </p>
                                                <p className='text text--sm save-card__old-price'>
                                                    ${elem.field_basic_price}
                                                </p>
                                            </div>
                                            <h5 className='save-card__product-name text text--sm'>
                                                {elem.field_description_images !== undefined ? Parser(elem.field_product_name) : 'Empty'}
                                            </h5>
                                            <p className='save-card__product-country text text--2sm'>
                                                {elem.field_country}
                                            </p>
                                        </div>
                                        <div className='save-card__bottom'>
                                            <p className='text text--2sm'>
                                                <FormattedMessage id='price_already' />
                                            </p>

                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                            : [1,2,3,4].map((elem,key) => (
                                <SwiperSlide key={key}>
                                    <Skeleton/>
                                </SwiperSlide>)) }
                    </Swiper>
                </div>
                <div className='save__bottom container'>
                    <a href={`/${language}/waw/save`}  className='save__more button button--secondary button--lg'>
                        <FormattedMessage id='see_more' />
                        <ArrowLong />
                    </a>
                </div>
            </section>
            <section className='subscribe'>
                <div className='subscribe__container container'>
                    <div className='subscribe__content'>
                        <h2 className='text text--lg'><FormattedMessage id='dont_miss' /></h2>
                        <p className='text text--md'>
                            <FormattedMessage id='notification_desc' />
                        </p>
                        <div className='subscribe__form'>
                            <input
                                onBlur={() =>
                                    setEmail(() => emailInput.current && emailInput.current.value)
                                }
                                ref={emailInput}
                                className='subscribe__email text text--sm'
                                type='email'
                                placeholder={intl.formatMessage({id:'notification_placeholder'})}
                            />
                            <a
                                className='subscribe__button button button--lg button--primary'
                                href={`/${language}/user/login?email=` + email}
                            >
                                <FormattedMessage id='notification_button' />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='collection'>
                <div className='collection__container container'>
                    <div className='collection__top'>
                        <h2 className='text text--2lg'><FormattedMessage id='unique_product_title' /></h2>
                        <p className='text text--sm'>
                            <FormattedMessage id='unique_product_desc' />
                        </p>
                    </div>
                    <Swiper
                        spaceBetween={24}
                        slidesPerView={'auto'}
                        className='collection__tabs'
                    >
                        {category.map((elem, key) => (
                            <SwiperSlide key={elem.id}>
                                <button
                                    onClick={() => setActiveCategory(elem.id)}
                                    className={
                                        'collection__tabs-item text text--4lg' +
                                        ' ' +
                                        (elem.id === activeCategory && 'active')
                                    }
                                >
                                    {elem.name}
                                </button>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={16}
                        className='collection__content'

                    >
                        { !isLoading && saveTop.length >= 1 && saveTop !== undefined && saveTop.filter(elem=>Number(elem.field_secret_category_1) == activeCategory) ?
                            saveTop.filter(elem=>Number(elem.field_secret_category_1) == activeCategory).map((elem)=> (
                                <SwiperSlide>
                                    <div className='collection-card'>
                                        <img
                                            className='collection-card__image'
                                            src={`https://app.weareway.com${elem.field_description_images.split(',')[0]}`}
                                            alt='Product photo'
                                        />

                                        <h5 className='collection-card__product text text--sm'>
                                            {Parser(elem.field_product_name)}
                                        </h5>
                                        <p className='collection-card__product-country text text--2sm'>
                                            {elem.field_country}
                                        </p>
                                        <a className='button button--secondary button--md' href={`https://app.weareway.com/${language}/user/landing?create=product&secret_id=${elem.nid}`}>
                                            <FormattedMessage id='add_to_cart' />
                                        </a>
                                    </div>
                                </SwiperSlide>
                            ))

                             : <SwiperSlide><Skeleton/></SwiperSlide>}
                    </Swiper>
                </div>
            </section>
            <section ref={elementToScroll} className='steps'>
                <div className='steps__container container'>
                    <div className='steps__top'>
                        <p className='text text--sm'><FormattedMessage id='how_works' /></p>
                        <h2 className='text text--2lg'>
                            <FormattedMessage id='step_title' />
                        </h2>
                    </div>
                    <div className='steps__content'>

                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>1</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_one_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_one_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>2</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_two_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_two_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>3</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_three_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_three_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                        <div className='steps__item'>
                            <div className='steps__item-content'>
                                <div className='steps__count'>4</div>
                                <h5 className='steps__title text text--md'><FormattedMessage id='step_four_title' /></h5>
                                <p className='steps__text text text--2sm'>
                                    <FormattedMessage id='step_four_desc' />
                                </p>
                            </div>
                            <div className='steps__arrow'></div>
                        </div>
                    </div>
                    <div className='steps__control'>
                        <a
                            href={`/${language}/user/login`}
                            className='button steps__button button--lg button button--secondary'
                        >
                            <FormattedMessage id='start_saving' />
                            <ArrowLong/>
                        </a>
                    </div>
                </div>
            </section>
            <section className='statistics'>
                <div className='statistics__container container'>
                    <div className='statistics__content'>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/user.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>320</h5>
                                <p className='text text--md'><FormattedMessage id='register_user' /></p>
                            </div>
                        </div>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/voyage.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>560</h5>
                                <p className='text text--md'><FormattedMessage id='voyage' /></p>
                            </div>
                        </div>
                        <div className='statistics__item'>
                            <img
                                className='statistics__icon'
                                src='/assets/images/order.svg'
                                alt='Icon'
                            />
                            <div className='statistics__text'>
                                <h5 className='text text--lg'>890</h5>
                                <p className='text text--md'><FormattedMessage id='orders' /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='app'>
                <div className='app__container container'>
                    <div className='app__content'>
                        <p className='text text--sm'><FormattedMessage id='app' /></p>
                        <h5 className='text text--2lg'><FormattedMessage id='app_desc' /></h5>
                        <div className='app__buttons'>
                            <a
                                href='https://apps.apple.com/app/weareway/id1644489646'
                                className='button button--xlg button--white'
                            >
                                <AppleIcon />
                                App Store
                            </a>
                            <a
                                href='https://play.google.com/store/apps/details?id=com.weareway.twa'
                                className='button button--xlg button--white'
                            >
                                <GooglePlay />
                                Google Play
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='faq'>
                <div className='faq__container container'>
                    <div className='faq__top'>
                        <p className='text text--sm'><FormattedMessage id='faq' /></p>
                        <h2 className='text text--2lg'>
                            <FormattedMessage id='faq_desc' />
                        </h2>
                    </div>
                    <div className='faq__content'>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_one_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_one_desc' />
                            </p>
                        </div>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_two_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_two_desc' />
                            </p>
                        </div>
                        <div className='faq__item'>
                            <h5 className='faq__title text text--4lg'>
                                <FormattedMessage id='faq_three_title' />
                            </h5>
                            <p className='faq__text text text--sm'>
                                <FormattedMessage id='faq_three_desc' />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='directions'>
                <div className='directions__top container'>
                    <p className='text text--sm'><FormattedMessage id='direction_title' /></p>
                    <div className='directions__header'>
                        <h2 className='text text--2lg'><FormattedMessage id='direction_desc' /> </h2>
                        <a
                            href={`/${language}/user/login`}
                            className='button button--text text text--3lg'
                        >
                            <FormattedMessage id='start_shopping' />
                            <ArrowLong />
                        </a>
                    </div>
                </div>
                <div className='directions__content'>
                    <div className='directions__item directions__mexico'>
                        <h5 className='text text--roboto'><FormattedMessage id='mexico' /></h5>
                    </div>
                    <div className='directions__item directions__usa'>
                        <h5 className='text text--roboto'><FormattedMessage id='usa' /></h5>
                    </div>
                    <div className='directions__item directions__uk'>
                        <h5 className='text text--roboto'><FormattedMessage id='uk' /></h5>
                    </div>
                    <div className='directions__item directions__germany'>
                        <h5 className='text text--roboto'><FormattedMessage id='germany' /></h5>
                    </div>
                    <div className='directions__item directions__turkey'>
                        <h5 className='text text--roboto'><FormattedMessage id='turkey' /></h5>
                    </div>
                </div>
                <div className='directions__control container'>
                    <a
                        href={`/${language}/user/login`}
                        className='button button--secondary button--lg'
                    >
                        <FormattedMessage id='start_shopping' />
                        <ArrowLong />
                    </a>
                </div>
            </section>
            <footer className='footer'>
                <div className='footer__container container'>
                    <div>
                        <a className='footer__logo' href='/'>
                            <Logo/>
                        </a>
                        <div className="language-switcher-f">
                            <p className='text text--sm'><FormattedMessage id='language_placeholder' />:</p>
                            <select value={language} onChange={(event)=>{
                                dispatch(languageSlice.actions.changeLanguage(event.target.value))}} className='text text--sm'>
                                <option value='en'>English</option>
                                <option value='ru'>Russian</option>
                            </select>

                        </div>
                    </div>
                    <div className='footer__content'>
                        <div className='footer__contacts'>
                            <h5 className='text text--2md'><FormattedMessage id='contacts' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>Email</p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:info@weareway.com'
                                >
                                    info@weareway.com
                                </a>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='phone_number' /></p>
                                <a
                                    className='footer__link text text--sm'
                                    href='mailto:+442037696167'
                                >
                                    +442037696167
                                </a>
                            </div>
                        </div>
                        <div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='middle_east_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='middle_east_address1' /><br/>
                                    <FormattedMessage id='middle_east_address2' /><br/>
                                    <FormattedMessage id='middle_east_address3' /><br/>
                                    <FormattedMessage id='middle_east_address4' /><br/>
                                    <FormattedMessage id='middle_east_address5' /><br/>
                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='middle_east_country' /></p>
                            </div>
                        </div>
                        <div className='footer__address'>
                            <h5 className='text text--2md'><FormattedMessage id='european_office' /></h5>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'>
                                    <FormattedMessage id='european_address1' /><br/>
                                    <FormattedMessage id='european_address2' /><br/>
                                    <FormattedMessage id='european_address3' /><br/>
                                    {/*<FormattedMessage id='european_address4' /><br/>*/}

                                </p>
                            </div>
                            <div className='footer__item'>
                                <p className='footer__text text text--sm'><FormattedMessage id='european_country' /></p>
                            </div>
                        </div>
                        <div className='footer__legal'>
                            <h5 className='text text--2md'><FormattedMessage id='legal_documents' /></h5>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/term-use-weareway`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='term_use' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    href={`/${language}/terms/privacy-policy`}
                                    className='footer__link text text--sm'
                                >
                                    <FormattedMessage id='privacy_policy' />
                                </a>
                            </div>
                            <div className='footer__item'>
                                <a
                                    className='footer__link text text--sm'
                                    href={`/${language}/terms/use-cookies`}
                                >
                                    <FormattedMessage id='use_cookies' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer__copyright container'>
                    <p className='text text--2sm'>
                        © {new Date().getFullYear()} WEAREWAY.CO LTD. <FormattedMessage id='right_reserved' />
                    </p>
                    <div className='footer__social'>
                        {' '}
                        <a
                            className='footer__social-button'
                            href='https://www.youtube.com/channel/UCBX_dsxetYMozv9jFjmU9gA'
                        >
                            <Youtube/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://instagram.com/we_are_way?igshid=YmMyMTA2M2Y='
                        >
                            <Instagram/>
                        </a>
                        <a className='footer__social-button' href='https://t.me/we_are_way'>
                            <Telegram/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://www.facebook.com/weareway2022'
                        >
                            <Facebook/>
                        </a>
                        <a
                            className='footer__social-button'
                            href='https://twitter.com/weareway'
                        >
                            <Twitter/>
                        </a>
                    </div>
                </div>
            </footer>
            { !isLoading && save[popupLoadCard] !== undefined &&
                <div className={'popup' + ' ' + (activePopup && 'is-open')}>
                    <div className='popup__blur'></div>
                    <div ref={popupWindowRef} className='popup__action'>
                        <button
                            disabled={popupLoadCard === 0}
                            onClick={() => setPopupLoadCard((prev: any) => prev - 1)}
                            className='popup__arrow popup__arrow--prev'
                        >
                            <ArrowRight/>
                        </button>
                        <div className='popup__window'>
                            <div className='popup__container'>
                                <div className='popup__header'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__back'
                                    >
                                        <ArrowBack/>
                                    </button>
                                    <h5 className='text text--2md'>
                                        {Parser(save[popupLoadCard].field_product_name)}
                                    </h5>
                                </div>
                                <div className='popup__control'>
                                    <button
                                        onClick={() => setActivePopup(false)}
                                        className='popup__close'
                                    >
                                        <CloseIcon/>
                                    </button>
                                </div>
                                <div className='popup__content'>
                                    <div className='popup__card-info'>
                                        <img
                                            className='popup__product-image'
                                            src={`https://app.weareway.com${save[popupLoadCard].field_description_images !== undefined && save[popupLoadCard].field_description_images.split(',')[0]}`}
                                            alt='Product'
                                        />

                                        <div className='popup__info'>
                                            <h5 className='text text--4lg'>
                                                {Parser(save[popupLoadCard].field_product_name)}
                                            </h5>
                                            <div className='popup__new-price'>
                                                <p className='text text--5lg'>

                                                    $ {save[popupLoadCard].field_discount_price}
                                                </p>
                                                <p className='popup__country text text--sm'>
                                                    {save[popupLoadCard].field_country}
                                                </p>
                                            </div>
                                            <p className='popup__caption text text--xsm'>
                                                <FormattedMessage id='price_already' />
                                            </p>
                                            <div className='popup__old-price'>
											<span className='text text--sm'>
												$ {save[popupLoadCard].field_basic_price}
											</span>
                                                <p className='text text--sm'><FormattedMessage id='original_price' /></p>
                                            </div>
                                            <div className='popup__actions'>
                                                <div className='popup__discount'>
                                                    <h5 className='text text--3lg'>
                                                        {-1 *
                                                            percent(
                                                                save[popupLoadCard].field_discount_price,
                                                                save[popupLoadCard].field_basic_price
                                                            )}
                                                        %
                                                    </h5>
                                                    <p className='text text--2xsm'><FormattedMessage id='can_save' /></p>
                                                </div>
                                                <a href={`/${language}/user/landing?create=product&secret_id=${save[popupLoadCard].nid}`}
                                                   className='popup__order button button--primary button--lg'>
                                                    <FormattedMessage id='order' />
                                                </a>
                                            </div>
                                            <a
                                                className='popup__shop button button--text'
                                                href={save[popupLoadCard].field_shop_url}
                                            >
                                                <ExternalLink/>
                                                <FormattedMessage id='where_buy' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__description'>
                                        <h5 className='text text--2sm'><FormattedMessage id='product_description' /></h5>
                                        {save[popupLoadCard].field_description_text ? (<>
                                            <p
                                                className={
                                                    'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                                }
                                            >
                                                {Parser(save[popupLoadCard].field_description_text)}
                                            </p>
                                            <a
                                                onClick={() => {
                                                    setIsOpenMore(prev => !prev)
                                                }}
                                                className='button text text--2sm'
                                            >
                                                <FormattedMessage id='more' />
                                                <ArrowRight/>
                                            </a></>) : <p
                                            className={
                                                'text text--2sm' + ' ' + (isOpenMore && 'is-open')
                                            }
                                        >
                                            No description
                                        </p>
                                        }
                                    </div>
                                    <div className='popup__divider'></div>
                                    <div className='popup__details'>
                                        <h5 className='text text--sm'><FormattedMessage id='details' /></h5>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_name' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_name)}
                                            </p>
                                        </div>
                                        <div className='popup__details-item'>
                                            <span className='text text--sm'><FormattedMessage id='shop_address' /></span>
                                            <p className='text text--sm'>
                                                {Parser(save[popupLoadCard].field_shop_address_term)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={popupLoadCard === save.length - 1}
                            onClick={() => setPopupLoadCard((prev: any) => prev + 1)}
                            className='popup__arrow popup__arrow--next'
                        >
                            <ArrowRight/>
                        </button>
                    </div>
                </div>

            }
        </>
    )
}

export default Root



